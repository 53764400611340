import {
  Avatar,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "store/seletors";
import { UserItem } from "types/user";

export default function ListClients() {
  const membersLuxTech = useSelector(selectors.user.getListUser);
  const allName = membersLuxTech.map((item) => item.name);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [randomUsers, setRandomUsers] = useState<string[]>([]);
  const [nameDestiny, setNameDestiny] = useState("");

  const selectUser = (user: UserItem) => {
    if (randomUsers.includes(user.name)) {
      const removeThisUser = randomUsers.filter((item) => item !== user.name);
      setRandomUsers(removeThisUser);
    } else {
      setRandomUsers((pre) => [...pre, user.name]);
    }
  };

  const startRandom = () => {
    onOpen();
    setNameDestiny("");

    setTimeout(() => {
      const randomNumber = Math.floor(Math.random() * randomUsers.length);
      setNameDestiny(randomUsers[randomNumber]);
      onClose();
    }, 3000);
  };

  return (
    <Box>
      <Box mb="20px" fontWeight={"700"} fontSize={"40px"}>
        Chào mừng đến với định mệnh cuộc đời bạn
      </Box>
      <Box mb="20px" fontWeight={"700"} fontSize={"24px"}>
        Chọn người vào định mệnh, click người mà bạn muốn đưa vào.
      </Box>
      <Flex gap={"20px"} flexWrap="wrap" mx="100px" justify={"center"}>
        {membersLuxTech.map((item) => {
          return (
            <Box
              key={item._id}
              cursor={"pointer"}
              onClick={() => selectUser(item)}
              _hover={{ opacity: "0.6" }}
            >
              <Avatar
                size={"xl"}
                src={item.avatarUrl}
                css={{
                  border: "2px solid white",
                }}
              />
              <Text>{item.name}</Text>
            </Box>
          );
        })}
      </Flex>
      <Flex
        my="20px"
        fontWeight={"700"}
        fontSize={"24px"}
        gap="20px"
        justify={"center"}
      >
        <Text>Những người trong định mệnh:</Text>
        <Button
          onClick={() => {
            setRandomUsers(allName);
          }}
        >
          All
        </Button>
        <Button
          onClick={() => {
            setRandomUsers([]);
            setNameDestiny("");
          }}
        >
          Reset
        </Button>
      </Flex>

      <Flex
        mt="30px"
        gap={"20px"}
        flexWrap="wrap"
        mx="100px"
        justify={"center"}
      >
        {randomUsers.map((item) => {
          return (
            <Box
              borderRadius={"8px"}
              p="10px 20px"
              fontSize={"20px"}
              bgColor={"green.500"}
              color="white"
            >
              {item}
            </Box>
          );
        })}
      </Flex>
      <Box mt="20px" fontWeight={"700"} fontSize={"24px"}>
        Định mệnh quyết là
      </Box>
      <Box fontWeight={"700"} fontSize={"40px"} color={"red"}>
        {nameDestiny}
      </Box>
      <Button
        mt="20px"
        fontWeight={"700"}
        fontSize={"24px"}
        p="50px"
        onClick={() => startRandom()}
        disabled={randomUsers.length === 0}
      >
        Hãy để định mệnh quyết định cuộc đời bạn (click here)
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody fontSize={"30px"} p="40px" m={"0 auto"}>
            <Text mb="20px">Định mệnh chọn ai...</Text>
            <Flex justify={"center"}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
