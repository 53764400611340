import baseService from "api/baseService";
import { AxiosResponse } from "axios";
import {
  AddTrackingTimeManually,
  EditTrackingTime,
  SubtractTrackingTime,
  TrackingTimeItem,
} from "types/trackingTime";

// export const getTrackingTimeActiveOfAuth = (
//   userId: string
// ): Promise<AxiosResponse> => baseService.get(`trackingTime/${userId}`);

export const getAllTrackingTimeUserByOrderApi = (
  userId: string
): Promise<AxiosResponse<TrackingTimeItem[]>> =>
  baseService.get(`trackingTime/${userId}/all`);

export const getCurrentTrackingTimeUserByAdmin = (
  userId: string
): Promise<AxiosResponse<TrackingTimeItem>> =>
  baseService.get(`trackingTime/${userId}/current`);

export const editTrackingTimeApi = (
  data: EditTrackingTime
): Promise<AxiosResponse<TrackingTimeItem>> =>
  baseService.patch(`trackingTime/edit`, data);

export const subtractTrackingTimeApi = (
  data: SubtractTrackingTime
): Promise<AxiosResponse<TrackingTimeItem>> =>
  baseService.patch(`trackingTime/subtract`, data);

export const addTrackingTimeManuallyApi = (
  data: AddTrackingTimeManually
): Promise<AxiosResponse<TrackingTimeItem>> =>
  baseService.post(`trackingTime/manual-log`, data);

export const deleteTrackingTimeApi = (
  id: string
): Promise<AxiosResponse<TrackingTimeItem>> =>
  baseService.delete(`trackingTime/${id}`);
