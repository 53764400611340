import { Box } from "@chakra-ui/react";
import Header from "components/Share/Header";
import Sidebar from "components/Sidebar";
import "./styles.scss";

interface GeneralLayoutProps {
  children: JSX.Element;
}

export default function GeneralLayout({ children }: GeneralLayoutProps) {
  return (
    <Box className="generalLayout">
      <Sidebar>
        <Box className="generalMain">
          <Header />
          <Box>{children}</Box>
        </Box>
      </Sidebar>
    </Box>
  );
}
