export const DATA_CONTENT = [
  {
    id: 0,
    text: `Khi bạn nhìn vào một thân cây hay một con người, từ sự tĩnh lặng ở trong bạn, thì ai đang nhìn vậy? Có một cái gì đó, sâu hơn là con người của bạn, đang nhìn. Đó là Tâm đang nhìn vào cái vật mà chính Tâm đã sáng tạo ra.
    - Eckhart Tolle -`,
  },
  {
    id: 1,
    text: `“Nếu bạn tin rằng bạn chỉ là thân tâm này, bạn sẽ chết!
    Nhưng khi bạn khám phá ra rằng bạn chính là Nhận Biết thì chuyện sinh tử chẳng bận lòng bạn nữa.”
    
    - Mooji - `,
  },
  {
    id: 2,
    text: `"Khi bên trong con có một cảm xúc tiêu cực nổ ra thì hãy thấu hiểu nó thay vì đánh nhau với nó. Tất cả các cảm xúc tiêu cực đều đến từ nỗi sợ một kết quả tiêu cực nào đó. Hãy cho cảm xúc đó xảy ra bất kể lý do gì, vì khi con cho nó xảy ra con mới thấy được bản chất của nó."

    - Trong Suốt -`,
  },
  {
    id: 3,
    text: `“Chúng ta cần học cách nghỉ ngơi và thư giãn. Nó giúp ta phòng chống bệnh tật và giảm ngừa căng thẳng. Nó giúp ta có tâm trí sáng suốt để tập trung giải quyết các vấn đề.”

    - Thích Nhất Hạnh - `,
  },
  {
    id: 4,
    text: `Nhớ rằng tôi sẽ chết, đó là công cụ quan trọng nhất tôi từng có để giúp mình thực hiện những lựa chọn lớn trong cuộc đời. Bởi vì hầu hết tất cả mọi thứ - tất cả những hy vọng phù phiếm, lòng kiêu hãnh, nỗi sợ mất mặt hay thất bại - tất cả những thứ này sẽ mờ nhạt đi khi ta đối diện với cái chết, chỉ để lại điều gì thật sự quan trọng.

    Nhớ rằng bạn sẽ chết, đó là cách tốt nhất tôi biết để tránh cái bẫy suy nghĩ rằng bạn vẫn còn thứ để mất. Bạn thực chất chẳng có gì. Không có lí do để không đi theo trái tim.
    
    - Steve Jobs - `,
  },
  {
    id: 5,
    text: `"Khi bạn thật sự hiểu rằng bạn chỉ là một mảnh nhỏ của sự sống trong một vũ trụ bất tận, bạn sẽ tự nhiên cúi đầu trước mọi thứ."

    - Sadhguru -`,
  },
  {
    id: 6,
    text: `Đừng lo cho ngày mai. Hãy để ngày mai mai lo. Hãy để lo lắng ngày nào đủ cho ngày ấy.”

    - Franco Zeffirells -`,
  },
  {
    id: 7,
    text: `"Nếu một việc gì đến mà ta không dũng cảm giải quyết cho tới nơi tới chốn, nó nhất định sẽ trở lại. Cuộc sống là vậy, nó sẽ lần lượt bắt mình thực hành mãi một bài học, cho đến khi thành thục mới thôi."

    - Sưu tầm -`,
  },
  {
    id: 8,
    text: `Chúng ta thường đánh giá thấp sức mạnh của nụ cười, của lời tử tế, của sự vỗ về, của tâm lắng nghe, của lời khen ngợi chân thành, hoặc một cử chỉ săn sóc rất nhỏ. Tất cả những điều ấy đều ẩn chứa khả năng thay đổi một cuộc đời.

    - Leo Buscaglia -`,
  },
  {
    id: 9,
    text: `"Mỗi khi bạn phán xét ai đó là bạn tiết lộ một phần của bản thân cần được chữa lành."

    - Joy Marino -`,
  },
  {
    id: 10,
    text: `"Mỗi khi bạn phán xét ai đó là bạn tiết lộ một phần của bản thân cần được chữa lành."

    - Joy Marino -`,
  },
  {
    id: 11,
    text: `"Mỗi khi bạn phán xét ai đó là bạn tiết lộ một phần của bản thân cần được chữa lành."

    - Joy Marino -`,
  },
  {
    id: 12,
    text: `Khi bạn nhìn vào một thân cây hay một con người, từ sự tĩnh lặng ở trong bạn, thì ai đang nhìn vậy? Có một cái gì đó, sâu hơn là con người của bạn, đang nhìn. Đó là Tâm đang nhìn vào cái vật mà chính Tâm đã sáng tạo ra.
    - Eckhart Tolle -`,
  },
  {
    id: 13,
    text: `“Bạn không phải là cái tâm này.

    Nếu bạn biết bạn không phải là cái tâm này, thì có gì khác biệt đâu khi cái tâm đó chộn rộn hoặc tĩnh lặng?
    
    Bạn không phải là cái tâm này.”
    
    - Nisargadatta -`,
  },
  {
    id: 14,
    text: `“Can đảm không phải là không biết sợ. Cam đảm là biết chấp nhận, dấn thân và vượt qua bất chấp mọi nỗi sợ.
    Cam đảm không có nghĩa là theo đuổi không ngừng, mà là biết từ bỏ những thứ quá quen thuộc nhưng không còn phù hợp.
    Con đường can đảm là con đường của trái tim.”
    
    - Osho -`,
  },
  {
    id: 14,
    text: `"Nghĩ về tình yêu thì rất dễ, còn yêu thì rất khó. Rất dễ để yêu cả thế giới, còn yêu một người cụ thể thì khó vô cùng."

    - Osho -
    `,
  },
  {
    id: 14,
    text: `"Ngày xửa ngày xưa, một người đàn ông bị mất chiếc rìu nghi là con của người hàng xóm. Cậu bé bước đi như một tên trộm, trông như một tên trộm và nói năng như một tên trộm. Nhưng người đàn ông đã tìm thấy chiếc rìu của mình khi đang đào trong thung lũng, và lần sau khi nhìn thấy con trai hàng xóm, cậu bé đi lại, nhìn và nói như bao đứa trẻ khác."

    - Lão Tử -`,
  },
  {
    id: 14,
    text: `“Bất cứ khi nào bạn cảm thấy bất ổn, điều đó có nghĩa là bạn đã xa rời thực tại. Bất cứ khi nào bạn ở gần thực tại - thì vô cùng yên ổn, bình thản, yên tĩnh, ân huệ, im lặng, bình an. Bạn đang ở nhà bởi lẽ thực tại là ngôi nhà của bạn.”

    - Osho -`,
  },
  {
    id: 14,
    text: `"Sự từ chối một cái đang là là nguyên nhân của khổ. Con khổ không phải vì điều đấy mà vì con từ chối điều đấy."

    - Trong Suốt -`,
  },
  {
    id: 14,
    text: `“Không hoàn hảo thì cũng chẳng sao đâu, vạn vật trên đời đều có vết nứt, đó là nơi ánh sáng sẽ chiếu vào.”

    - Chill Radio -`,
  },
  {
    id: 14,
    text: `"Chúng ta mang bên trong mình những điều kỳ diệu mà chúng ta tìm kiếm bên ngoài chúng ta."

    - Rumi -`,
  },
  {
    id: 14,
    text: `Thực tại luôn luôn thật với chính nó. Khi hòa hợp với thực tại, bạn sẽ trải nghiệm hạnh phúc. Khi không hòa hợp với nó, lập tức bạn trải nghiệm nỗi đau. Đây là quy luật của vũ trụ; là cách mọi thứ diễn ra. Không ai thoát khỏi quy luật này. Đối với tôi, hiểu biết này là một ân huệ. Thực tại là bất biến. Tranh cãi với nó, chống lại nó, thì nó sẽ gây tổn thương - lần nào cũng vậy. Nó sẽ làm tổn thương bạn, làm tổn thương người khác, và nó sẽ góp phần vào xung đột chung của tất thảy chúng sinh.

    - Adyashanti -`,
  },
  {
    id: 14,
    text: `Đừng nên vội vàng tìm câu trả lời cho cuộc sống;
    Đừng nên vội vàng mong muốn cuộc sống sẽ cho bạn biết hết các câu trả lời;
    Đôi khi, bạn cần phải kiên nhẫn chờ đợi.
    
    - Sưu tầm -`,
  },
  {
    id: 14,
    text: `Bản chất mọi đau khổ
    Do suy nghĩ mà ra
    Chớ vội tin đúng - thật
    Lập tức nhẹ hồn ta. - Minh Canh -`,
  },
  {
    id: 14,
    text: `“Bất kể điều gì làm phiền bạn, bất kể điều gì làm bạn khó chịu, đó là thầy của bạn.”

    - Ajahn Chah -`,
  },
  {
    id: 14,
    text: `"Nguyên nhân chính của bất hạnh không bao giờ là hoàn cảnh mà là suy nghĩ của bạn về nó."

    - Eckhart Tolle -`,
  },
  {
    id: 14,
    text: `Đối đầu với cuộc sống. Gặp gỡ cuộc sống. Những khoảnh khắc khó khăn sẽ có nhưng một ngày bạn sẽ thấy rằng những khoảnh khắc khó khăn đó đã cho bạn sức mạnh vì bạn đã gặp phải chúng.Nếu không có chúng, bạn sẽ không bao giờ được định tâm, được bắt rễ."

    - Osho -`,
  },
  {
    id: 14,
    text: `"Bạn không đau khổ vì mọi thứ là vô thường. Bạn đau khổ vì mọi thứ là vô thường và bạn nghĩ rằng chúng là vĩnh viễn."

    - Thích Nhất Hạnh - `,
  },
  {
    id: 14,
    text: `“Hãy cảm ơn những lúc bạn gặp khó khăn, bởi nếu không có khó khăn, bạn sẽ không có cơ hội để hiểu mình và trải nghiệm cuộc sống.”

    - Sưu tầm -`,
  },
  {
    id: 14,
    text: `Tâm bất an: Đó là khi chúng ta không biết trân trọng những gì mình đang có, để rồi nuối tiếc khi mất đi những gì mình đã có.

    Tâm an lạc: Đó là khi chúng ta biết hài lòng với những gì mình đang có, dù vậy, vẫn chấp nhận khi mọi sự đổi thay.
    
    - Gyalwa Dokhampa -`,
  },
  {
    id: 14,
    text: `Nhẫn nại là một đức tính tốt cần rèn luyện. Nhẫn nại không có nghĩa là để cho người khác lấn áp hay tự do lạm dụng bạn; mà có nghĩa là hãy bình tĩnh để diễn đạt mình một cách hữu hiệu và đúng lúc, đúng nơi, đúng lời, đúng việc. - Thiền sư Bhate Henepola Gunaratana -`,
  },
  {
    id: 14,
    text: `"Muốn bình an cũng là một loại bất an, muốn càng nhiều thì bất an càng tăng."

    - Trong Suốt -`,
  },
  {
    id: 14,
    text: `“Thân thể này vô thường. Tâm này cũng vô thường. Mọi tướng, mọi trạng thái, mọi tình trạng tâm, bất kỳ cảm xúc hay suy nghĩ nào cũng vô thường cả.￼ Tức là tự đến rồi tự biến đổi, tự biến mất. Khi thấy rõ như thế thì sẽ tự không dính mắc, níu kéo bất cứ điều gì, dù là thân, tâm, hay mọi thứ trong cuộc sống. Ngay kể cả khi ta ngồi thiền, nhập vào trạng thái định sâu và thấy rất hỉ lạc thì nó cũng không kéo dài mãi. Nó cũng vô thường. Nó sẽ phải thay đổi.￼”

    - Phan Việt -`,
  },
  {
    id: 14,
    text: `“Một người tức giận là do không giải quyết được những đau buồn của mình. Họ là nạn nhân đầu tiên của sự đau buồn đó, còn bạn là người thứ hai. Hiểu được điều này, lòng tư bi sẽ nảy nở trong tim và sự tức giận sẽ tan biến. Đừng trừng phạt họ, thay vào đó, hãy nói gì đó, làm gì đó để vơi bớt nỗi đau buồn.”

    - Thích Nhất Hạnh - `,
  },
  {
    id: 14,
    text: `Đời dạy ta muôn ngàn cách Nắm giữ,
    Đạo dạy người chỉ một chữ Buông.”
    
    - Thích Chánh Định - `,
  },
  {
    id: 14,
    text: `“Đừng nhìn ra bên ngoài để tìm kiếm khoái cảm hay sự thỏa mãn, hay sự phê chuẩn, ổn định, hay tình yêu – bạn có một kho báu bên trong lớn hơn vô hạn lần so với bất cứ thứ gì mà thế giới có thể cung cấp.”

    - Eckhart Tolle -`,
  },
  {
    id: 14,
    text: `“Những mầm mống khổ đau trong bạn có thể thật mạnh mẽ, nhưng đừng đợi cho đến khi mọi khổ đau đi hết rồi mới cho phép mình được hạnh phúc.”

    - Thích Nhất Hạnh -`,
  },
  {
    id: 14,
    text: `“Nghĩ về quá khứ và tương lai là không sai. Sai là đắm chìm trong quá khứ và tương lai mà quên mất thực tại đang là." - Hoà Thượng Viên Minh -`,
  },
  {
    id: 14,
    text: `"Hạnh phúc là một loại đau khổ vi tế. Bởi chỉ mong muốn điều hạnh phúc, dễ chịu là còn khổ, còn bám víu vào hạnh phúc là còn khổ.

    Đau khổ là một loại phước báu vi tế. Đau khổ càng lớn thì món quà càng to. Bởi nhận ra đau khổ làm bạn không còn muốn bám víu, không còn muốn dính mắc tâm bạn vào nó. Không còn dính mắc thì bạn được tự do.
    
    Hạnh phúc chưa hẳn đã tốt. Đau khổ chưa hẳn là không tốt. Đúng - sai, tốt - xấu: tất cả chỉ là tương đối. Vượt trên cả hạnh phúc và đau khổ, bạn sẽ tìm lại (ngộ ra) chính mình."
    
    - Sưu tầm - `,
  },
  {
    id: 14,
    text: `5 NGUYÊN TẮC ĐỂ HẠNH PHÚC:

    1. Tập tha thứ
    2. Bớt lo lắng
    3. Sống đơn giản
    4. Cho đi nhiều hơn
    5. Đừng trông đợi quá nhiều.
    
    - Sưu tầm -`,
  },
  {
    id: 14,
    text: `“Không nên xem con đường tâm linh như là niềm vui thích hay thứ gì đó cao sang, mà nên xem nó như là việc đối mặt với sự thật về cuộc sống.”

    - Chögyam Trungpa -`,
  },
  {
    id: 14,
    text: `“Nếu bạn không có được điều bạn muốn có nghĩa là cái gì đó tốt đẹp hơn đang đến.”

    - Sưu tầm -`,
  },
  {
    id: 14,
    text: `"Không ai có thể quay ngược lại thời gian để bắt đầu lại từ đầu, nhưng bất kì ai cũng có thể bắt đầu từ ngày hôm nay và tạo ra một kết thúc mới.”

    - Sưu tầm -`,
  },
  {
    id: 14,
    text: `Những suy nghĩ tiêu cực chỉ có thể mang lại cho bạn một cuộc sống bất lực.

    Những suy nghĩ tích cực chỉ có thể mang lại cho bạn một cuộc sống đỡ bực.
    
    Khi thấy rõ mọi điều mà không còn thấy điều gì tiêu cực hay tích cực,
    Bạn sẽ an nhiên và đùa nghịch giữa muôn trùng cuộn sóng của cuộc đời.
    
    - Trong Suốt -`,
  },
  {
    id: 14,
    text: `"Tha thứ có nghĩa là chúng ta thả một người ra khỏi nhà tù, và thật ngạc nhiên, khi thấy chính mình là người tù này" - Lewis B.Smedes -`,
  },
  {
    id: 14,
    text: `“Bạn chỉ đẹp khi là chính bạn. Bạn đâu cần sự công nhận của người khác. Bạn chỉ cần chấp nhận chính mình mà thôi.”

    - Thích Nhất Hạnh -`,
  },
  {
    id: 14,
    text: `"Sự thật là trong cuộc sống mọi thứ đều thay đổi – dù bạn muốn hay không, dù bạn có ngồi bên bờ sông mà ước rằng “sông ơi hãy ngừng chảy, mùa ơi thôi đừng đi, hoa ơi xin đừng tàn, tuổi trẻ đừng giã biệt, tuổi già xin đừng đến, cuộc đời ơi xin chớ đưa ta đến nấm mồ”." - Trích sách "Cỏ tự nó mọc lên" - Osho -`,
  },
  {
    id: 14,
    text: `“Nếu bạn muốn thành công, hãy tôn trọng một quy luật: Đừng bao giờ nói dối chính mình.”

    - Paulo Coelho -`,
  },
  {
    id: 14,
    text: `CHẤP NHẬN

    Khi ai đó chê trách bạn, hãy bình tâm lắng nghe. Khoan vội cắt lời họ và biện hộ cho mình.
    
    Nếu lời chê trách ấy đúng, đồng ý, xin lỗi và tìm xem bạn có thể làm gì để thay đổi.
    
    Nếu lời chê trách ấy là sai, vui vẻ nói: “Một ý hay, nhưng tôi có quan điểm của riêng mình.”
    
    - Sưu tầm -`,
  },
  {
    id: 14,
    text: `“Nếu bạn được hỏi: Im lặng là gì?
    Câu trả lời: Đó là viên đá đầu tiên của ngôi đền Trí tuệ.”
    
    - Pythagoras -`,
  },
  {
    id: 14,
    text: `“Logic sẽ đưa chúng ta từ điểm A tới điểm B. Trí tưởng tượng sẽ đưa chúng ta tới mọi nơi.”

    - Albert Einstein -`,
  },
  {
    id: 14,
    text: `“Nếu con đi tìm Sự thật thì Sự thật sẽ bảo vệ con.”

    - Ben Stassen & Jeremy Degruson -`,
  },
  {
    id: 14,
    text: `“Không đạt được những gì bạn mong muốn đôi khi lại là một may mắn tuyệt vời.”

    - Đức Đạt Lai Lạt Ma - `,
  },
  {
    id: 14,
    text: `"Chỉ cần làm tốt. Đừng hỏi về con đường phía trước."

    - Zen Proverb - `,
  },
  {
    id: 14,
    text: `Một tâm thức chấp trụ và phân biệt đã tạo ra thời gian và không gian hữu hạn, nghĩa là tạo ra sanh tử ta - người, đây - kia, đã qua - sắp tới, còn - mất, có - không...”

    - Đoàn Trung Còn -`,
  },
  {
    id: 14,
    text: `"Không có một lý do chính đáng nào để tranh cãi với thực tại, bởi vì chúng ta sẽ không bao giờ chiến thắng trong cuộc chiến này."

    - Adyashanti -`,
  },
  {
    id: 14,
    text: `Từ bỏ sự phán xét đối với người khác không có nghĩa là bạn sẽ không còn khả năng nhận ra được sự tha hóa hay thiếu hiểu biết của người khác khi bạn gặp phải những hành vì như thế. Đây chỉ là bạn "chọn làm một con người hiểu biết" hơn là một "con người hay phản ứng" hay một vị quan tòa."

    - Eckhart Tolle -`,
  },
  {
    id: 14,
    text: `“Khi bạn nghĩ rằng mọi sự do sai lầm của người khác, bạn sẽ nhiều khổ đau. Khi bạn nhận ra rằng mọi sự đều bắt nguồn từ chính mình, bạn sẽ học được cả hai bài học: bình yên và vui sướng.”

    - Dalai Lama -`,
  },
  {
    id: 14,
    text: `Tất cả mọi hoàn cảnh trong cuộc đời, dù tốt hay xấu, đều là những cơ hội để học hỏi.

    Có khả năng chấp nhận được sự không chắc chắn, và sống với nó là một dấu hiệu chắc chắn của sự trưởng thành.
    
    Chúng ta thường cứ muốn phải chắc chắn về tương lai. Chẳng cần như thế, cái gì đến, sẽ đến.
    
    - Sayadaw U Jotika -`,
  },
  {
    id: 14,
    text: `“Khi tôi nói bạn cần sẵn sàng cho điều tồi tệ nhất, điều đó không có nghĩa là bạn cần phải có một quan điểm hay thái độ tiêu cực; chỉ đơn giản là bạn hãy gạt bỏ mọi kỳ vọng và mong đợi - hãy linh hoạt và sẵn sàng đón nhận bất cứ điều gì xảy đến.”

    - Gyalwang Drukpa -`,
  },
  {
    id: 14,
    text: `Khi đau đớn, khổ sở hay giận dữ xảy ra, đã đến lúc bạn phải nhìn vào bên trong chứ không phải nhìn xung quanh.

    - Sadhguru -`,
  },
  {
    id: 14,
    text: `Bám luyến là cái đối địch nhất của yêu thương.
    Yêu thương nói: “Ta muốn bạn được hạnh phúc”.
    Bám luyến nói: “Ta muốn bạn làm cho ta hạnh phúc”.
    
    - Tenzin Palmo -`,
  },
  {
    id: 14,
    text: `“Vào mỗi buổi sáng, chúng ta được sinh ra một lần nữa. Những gì chúng ta làm trong ngày hôm nay mới là điều đáng quan tâm nhất.”

    - Lời Phật dạy - `,
  },
  {
    id: 14,
    text: `“Đôi khi, thứ tốt nhất bạn có thể làm là không nghĩ ngợi, không ao ước, không tưởng tượng, không đuổi bắt. Bạn chỉ cần tập trung vào hơi thở và tin chắc rằng mọi chuyện vốn đều hoàn hảo.”

    - Sưu tầm -`,
  },
  {
    id: 14,
    text: `“Thay vì bỏ công tìm kiếm cái mà bạn không có, hãy nhận ra cái mà bạn Chưa Bao Giờ Mất.”

    - S.N. Maharaj -`,
  },
  {
    id: 14,
    text: `“Biến mình trở nên quyến rũ hơn trong mắt người khác có giúp bạn hạnh phúc hơn?

    Nói dối lòng mình để làm vừa lòng người bạn đời sẽ giúp bạn có thêm tình yêu?" - Byron Katie - 
    `,
  },
  {
    id: 14,
    text: `“Nếu bạn thích một bông hoa, đừng ngắt nó ra khỏi cành.
    Bởi vì nếu bạn ngắt nó, nó sẽ chết và không còn là bông hoa đẹp cho bạn ngắm nhìn nữa.
    Yêu thương không phải sở hữu.
    Mà yêu thương là trân trọng.”
    
    - Osho -`,
  },
  {
    id: 14,
    text: `“Chú tâm đến sự công nhận của người khác rồi người sẽ trở thành tù nhân của chính họ.”

    - Lão Tử -`,
  },
  {
    id: 14,
    text: `“Nếu biết vạn vật đều thay đổi, thì bản thân không nên cố nắm giữ điều chi.”

    - Lão Tử -`,
  },
  {
    id: 14,
    text: `“Trong thế giới phức tạp này, cách tốt nhất để sống được đó là hãy chân thành.”

    - Sogyal Rinpoche -`,
  },
  {
    id: 14,
    text: `"Khi tôi chấp nhận chính tôi, tôi trút xuống gánh nặng mong cầu người khác chấp nhận mình."

    - Sưu tầm -`,
  },
  {
    id: 14,
    text: `" Cuộc sống quá ngắn ngủi để mọi người kịp nhận ra sự nuối tiếc. Hãy trân trọng và yêu thương những người quý mến bạn và hãy tạm quên đi những người đối xử chưa tốt với bạn."

    - Sưu tầm -`,
  },

  {
    id: 14,
    text: `“Tình yêu là bản chất của bạn. Tình yêu không tìm kiếm cái gì cả. Tình yêu tự nó là hoàn hảo. Tình yêu không muốn, không cần và cũng không phải làm gì cả. Tình yêu đã có tất cả những gì nó muốn. Tình yêu chính là tất cả những gì nó muốn, chỉ khác là cách nó muốn mà thôi. Vậy nên khi tôi nghe ai đó nói rằng họ yêu một người và muốn được người đó yêu lại, tôi biết là họ không nói đến tình yêu. Họ đang nói về một thứ khác.”

    - Byron Katie `,
  },
  {
    id: 14,
    text: `“Hiểu biết của bạn về người khác bị hạn chế bởi những gì bạn cho rằng mình đã biết.”

    - Byron Katie -`,
  },
  {
    id: 14,
    text: `"Cái đẹp thực sự không ở trong sự hoàn hảo, mà ở trong sự chấp nhận và mở lòng ra với những bất toàn."

    - Ajahn Brahm -`,
  },
  {
    id: 14,
    text: `"Bạn không thể làm dịu cơn bão. Hãy làm dịu chính mình. Rồi cơn bão sẽ qua đi."

    - Sưu tầm -`,
  },
  {
    id: 14,
    text: `“Khi bạn ghét chính mình, bạn sẽ ghét tất cả mọi người. Đó là quy luật.”

    - Byron Katie -`,
  },
  {
    id: 14,
    text: `"Khi bạn muốn có thêm điều gì, bạn không thể vui hưởng điều mình đang có."

    - Ajahn Brahm -`,
  },
  {
    id: 14,
    text: `“Vũ trụ không mang đến cho bạn người bạn muốn. Vũ trụ mang đến cho bạn người bạn cần. Người giúp đỡ bạn, làm tổn thương bạn, rời bỏ bạn, yêu thương bạn và tạo nên chính con người bạn.”

    - Spiritual League - `,
  },
  {
    id: 14,
    text: `"Khi bạn nói hay làm bất cứ việc gì để làm vừa lòng hay kiểm soát ai hay thứ gì đó, nỗi sợ là nguyên nhân và niềm đau là kết quả. Thao túng đồng nghĩa với chia ly, và chia ly thì rất đau khổ. Một người có thể yêu bạn hết mình trong khoảnh khắc đó và bạn không có cách nào để nhận được tình yêu, bởi bạn đang mắc kẹt với suy nghĩ về những gì bạn phải làm để nhận được tình yêu. Mọi suy nghĩ tiêu cực sẽ làm bạn xa cách với mọi người." - Byron Katie -`,
  },
  {
    id: 14,
    text: `"Những điều bạn nghĩ "đáng lẽ không nên xảy ra" chính là những điều "nên xảy ra". Nó nên xảy ra vì nó đã xảy ra rồi. Không có suy nghĩ nào thay đổi được điều đó. Điều này không có nghĩa là bạn bỏ qua hay chịu đựng nó, mà có nghĩa là bạn có thể nhìn nhận mọi việc khi không còn sự kháng cự hay sự hỗn loạn bên trong bạn. Không ai muốn con của mình đau bệnh. Không ai muốn bị tai nạn giao thông. Nhưng khi điều đó xảy ra, liệu tranh cãi với thực tại thì có ích gì?." - Byron Katie - `,
  },
  {
    id: 14,
    text: `“Đôi khi bạn có ấn tượng đầu tiên rất tốt về người nào đó.
    Nhưng chưa được bao lâu thì mối quan hệ giữa bạn và người đó nhanh chóng trở nên xấu đi.
    Đó là vì khi mới gặp, bạn đã không nhìn người đó như chính con người thật của họ.
    Mà chỉ thấy sự kỳ vọng và tưởng tượng của mình đắp lên họ mà thôi￼.”
    
    - Hae Min -`,
  },
  {
    id: 14,
    text: `“Suy nghĩ luôn vô hại, trừ khi ta tin nó là thật. Vấn đề không nằm ở suy nghĩ của ta, mà ở cách chúng ta bám chấp vào suy nghĩ của mình. Đó mới chính là điều gây ra đau khổ. Bám chấp vào một suy nghĩ là mặc định rằng nó đúng, mà không hề kiểm tra lại. Một niềm tin thường là một suy nghĩ gắn với ta trong nhiều năm liền.”

    - Byron Katie -`,
  },
  {
    id: 14,
    text: `“Biết cô đơn chính là biết yêu người. Nghe có vẻ nghịch lý, nhưng đúng là thế. Một sự thật không thể chối cãi là, chỉ những người có khả năng ở trong cô độc mới có năng lực yêu, năng lực chia sẻ, năng lực đi đến tận cùng nội tâm của người khác mà không sở hữu người khác, không phụ thuộc vào người khác, không biến người khác thành món đồ, và không chìm đắm vào người khác.

    Họ cho phép người khác được tự do tuyệt đối, bởi vì họ biết rằng nếu người đó bỏ đi, họ vẫn sẽ hạnh phúc. Họ không sợ hãi sự cô đơn. Họ có khả năng hạnh phúc với chính mình. Niềm hạnh phúc của họ không thể bị tước đoạt bởi vì nó chưa từng được vay mượn từ bất cứ ai.”
    
    - Osho -`,
  },
  {
    id: 14,
    text: `“Một con chim đậu trên cành cây, không bao giờ sợ cành cây bị gãy. Bởi vì niềm tin của con chim đặt ở đôi cánh của nó chứ không phải ở cành cây.
    Vì thế, dù bạn đang đứng trong hoàn cảnh nào, hãy luôn tin tưởng vào chính bản thân mình.”
    
    - Sưu tầm `,
  },
  {
    id: 14,
    text: `“Quen một người cần dựa vào DUYÊN PHẬN.

    Hiểu một người cần dựa vào SỰ NHẪN NẠI.
    
    Chinh phục một người cần dựa vào TRÍ TUỆ.
    
    Chung sống với một người cần dựa vào LÒNG BAO DUNG.”
    
    - Sưu tầm -`,
  },
  {
    id: 14,
    text: `"Không ai và chẳng có gì có thể giải thoát cho bạn. Chỉ có sự hiểu biết của chính bạn mới giải thoát cho bạn mà thôi.”

    - Thiền sư Ajahn Chah -`,
  },
  {
    id: 14,
    text: `"Đừng bắt ép một người vào khuôn khổ bạn muốn họ phải thế. Con người tuyệt vời nhất khi là chính họ."

    - Albert Einstein -`,
  },
  {
    id: 14,
    text: `"Trước hết phải thành thật với chính bản thân mình, rạch ròi như đêm với ngày, sau đó, bạn mới có thể sống chân thành với người khác."

    - William Shakespeare -`,
  },
  {
    id: 14,
    text: `“Kiếp sau có khi tới sớm hơn ngày mai.”

    - Danh ngôn Tây Tạng -`,
  },

  {
    id: 14,
    text: `Mọi chuyện trên thế gian này chẳng tùy thuộc vào ta. Chúng đi theo chiều hướng tự nhiên của chúng. Cơ thể này có đường lối đi riêng của nó, ta không thể xen vào. Ta có thể làm cho thân này đẹp thêm chút ít, hấp dẫn hơn và sạch sẽ trong chốc lát như các cô gái để móng tay dài và tô son hồng, tạo ra vẻ duyên dáng đẹp đẽ. Nhưng khi tuổi già đến thì ai cũng như ai. Đường lối của cơ thể là như thế, vượt ra ngoài khả năng kiểm soát của chúng ta. Chỉ một điều chúng ta có thể làm được, đó là làm đẹp tâm hồn mình.
    - Ajahn Chah -`,
  },
  {
    id: 14,
    text: `"Tình yêu không có vĩnh cửu, mà chỉ có những khoảnh khắc vĩnh cửu của tình yêu thôi. Mà cái khoảnh khắc cắt đứt nó cũng rất đẹp, rất trọn vẹn." - Trong Suốt -`,
  },
  {
    id: 14,
    text: `"Vì chúng ta hiểu rằng đó là quả của một cái nhân trong quá khứ nào đó đã nở ra rồi. Chỉ đơn giản là vậy thôi. Dù rằng chúng ta bị phản bội, bị người kia chà đạp… Ta hiểu rằng, đau khổ ở đời này cũng là một cái nghiệp chúng ta phải trả." - Trong Suốt -`,
  },
  {
    id: 14,
    text: `"Trí tuệ là gì? Trí tuệ là mình hiểu sự thật. Tình thương là gì? Tình thương là mình thông cảm và quan tâm đến quyền lợi của người khác. Nếu có tình thương và trí tuệ, hành động của mình sẽ đúng và có ích. Nếu chỉ có tình thương, không có trí tuệ thì chuyện gì sẽ xảy ra? Ví dụ: Ở đây có một đứa bé đang khóc, ai cũng đứng lên rút tiền đưa cho nó. Nhưng đứa trẻ vẫn khóc vì nó cần một bình sữa thôi mà. Đấy là điển hình của việc có tình thương mà không có trí tuệ. Còn trí tuệ mà không có tình thương thì sao? Hiểu đứa bé cần sữa nhưng bảo: “Thôi, kệ nó. Nó chả phải con tôi. Bố mẹ nó tự chịu trách nhiệm”. Cả hai trường hợp đều không đủ. Nên là trong mọi hành động, mình nên tập trau dồi cả tình thương và trí tuệ. Trí tuệ là hiểu sự thật; tình thương là quan tâm và thực sự mong muốn điều tốt cho người khác." - Trong Suốt -`,
  },
  {
    id: 14,
    text: `"Tình yêu là cách chuyển hoá nhanh nhất vì khi có cảm xúc mình hiểu rõ nhât mình thế nào. Hiểu rõ bản thân mình mới có thể chuyển hoá được." - Trong Suốt -`,
  },
  {
    id: 14,
    text: `"Hay là trong mối quan hệ tình cảm, nếu mình còn đầy đau khổ, mình không thể giúp người kia được. Sớm muộn gì đau khổ của mình cũng sẽ bộc lộ ra ngoài và gây đau khổ cho người kia. Nên là hiểu điều đấy một cách sâu sắc, mình sẽ quan tâm đến mình đúng mức. Mình sẽ không có suy nghĩ “sẽ hi sinh hết để người khác được hạnh phúc” – vì mình hiểu rằng cái đấy không thể xảy ra được, chứ không phải mình không làm được." - Trong Suốt -`,
  },
  {
    id: 14,
    text: `"Sự việc đã xảy ra rồi thì có gì đâu, chính sự không-chấp-nhận-nổi mới là vấn đề." - Trong Suốt -`,
  },
  {
    id: 14,
    text: `- Trong Suốt -`,
  },
  {
    id: 14,
    text: `- Trong Suốt -`,
  },
  {
    id: 14,
    text: `- Trong Suốt -`,
  },
  //chu nghia khac ky

  {
    id: 0,
    text: `"Sự trong sạch cho ta thời gian rỗi rãi, sự phóng đáng thì không cho ta một giây nghỉ ngơi". - Seneca -`,
  },

  {
    id: 0,
    text: `"Chúng ta không bao giờ có thể vừa hạnh phúc vừa khao khát những thứ không có trong hiện tại.". - Seneca -`,
  },

  {
    id: 0,
    text: `"“Dòng chảy và sự thay đổi” của thế giới xung quanh chúng ta không phải là một sự tình cờ mà là một phần thiết yếu của vũ trụ.". - Vô Danh -`,
  },

  {
    id: 0,
    text: `"Cơn giận của chúng ta luôn kéo dài lâu hơn những tổn thất mà ta phải chịu.". - Seneca -`,
  },

  {
    id: 0,
    text: `"Chúng ta sẽ khám phá ra rằng:“Bản chất của con người rất giống với loài ong. Một con ong không thể sống đơn độc: nó sẽ chết nếu bị cô lập”". - Musonius -`,
  },
  {
    id: 0,
    text: `"Hãy chú ý đến kẻ thù của bạn, vì họ là những người đầu tiên phát hiện ra lỗi lầm của bạn.". - Antisthenes -`,
  },

  {
    id: 0,
    text: `"Muốn biết có bao nhiêu người ghen tị với bạn, hãy đếm số người ngưỡng mộ bạn.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Thay vì dành cả đời để làm những việc cần phải làm, chúng ta nên dành thời gian đó làm những việc mình muốn làm.". - Vô Danh -`,
  },
  {
    id: 0,
    text: `"Người có thể thích nghi với những đều kiện khiêm tốn và sống giàu có với tài sản ít ỏi là người đàn ông thực sự giàu có. ". - Seneca -`,
  },
  {
    id: 0,
    text: `"Hãy nhớ rằng mọi thứ chúng ta có đều là “vay mượn” từ Vận mệnh, nó có thể lấy lại mà không cần sự cho phép của chúng ta – kỳ thực là còn không cần thông báo trước.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Điều không đúng thì đừng làm. Lời không thật thì đừng nói.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Chúng ta không dám làm nhiều việc vì khó, nhưng khó vì không dám làm.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Giận dữ: một loại axit có thể gây hại nhiều hơn cho vật chứa đựng nó hơn là bất cứ thứ gì mà nó được đổ vào.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Trở ngại lớn nhất của cuộc sống là sự chờ đợi của ngày mai và sự mất mát của ngày hôm nay
      ". - Seneca -`,
  },
  {
    id: 0,
    text: `"Không có ai kém may mắn hơn người đàn ông quên đi nghịch cảnh, bởi vì anh ta không có cơ hội để thử thách bản thân". - Seneca -`,
  },
  {
    id: 0,
    text: `"Những gì luật pháp không cấm, sự trung thực có thể cấm nó". - Seneca -`,
  },
  {
    id: 0,
    text: `"Giá trị của món quà không nằm trong món quà, mà trong chính tấm lòng của người trao tặng.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Ðiều quyến rũ nhất của mọi hạnh phúc là có được một tình bạn vững bền và dịu dàng. Tình bạn xoa dịu đi mọi lo lắng, xua tan mọi buồn phiền và khuyên nhủ ta khi bất hạnh.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Ai dũng cảm thì tự do.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Chúng ta thường đau khổ trong trí tưởng tượng hơn là trong thực tế.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Vô minh là nguyên nhân của sự sợ hãi.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Trong khi chúng ta chờ đợi cuộc sống, cuộc sống trôi qua.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Chỉ có thời gian mới có thể chữa được những gì lý do không thể.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Đó không phải là người có ít là người nghèo, mà là người khao khát nhất.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Tất cả sự tàn ác phát sinh từ sự yếu đuối. ". - Seneca -`,
  },
  {
    id: 0,
    text: `"Đôi khi ngay cả sống là một hành động của lòng can đảm.". - Seneca -`,
  },
  {
    id: 0,
    text: `"May mắn là những gì xảy ra khi sự chuẩn bị gặp cơ hội.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Giữ lấy sự nhiệt tình trẻ trung của bạn, bạn có thể sử dụng nó tốt hơn khi bạn già.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Cuộc sống chúng ta nhận được không ngắn, nhưng chúng ta làm theo cách đó. Chúng ta không được cung cấp kém, nhưng chúng ta lãng phí những gì chúng ta có.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Một thanh kiếm không bao giờ giết chết bất cứ ai. Nó là một công cụ trong tay của kẻ giết người. Lời nói cũng vậy.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Nếu bạn thực sự muốn thoát khỏi những thứ quấy rối bạn, điều bạn cần không phải là ở một nơi khác, mà là một người khác.". - Seneca -`,
  },

  {
    id: 0,
    text: `"Cuộc sống còn dài, nếu bạn biết cách sống.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Không phải là chúng ta có ít thời gian, mà là chúng ta lãng phí rất nhiều.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Một trong những đặc điểm đẹp nhất của tình bạn chân chính là hiểu và được hiểu.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Cái chết là mong muốn sâu sắc nhất của một số người, niềm an ủi của nhiều người và cái kết thuộc về tất cả.". - Seneca -`,
  },

  {
    id: 0,
    text: `"Việc chinh phục nỗi đau của chúng ta là khôn ngoan hơn là cố gắng lừa dối nó.". - Seneca -`,
  },

  {
    id: 0,
    text: `"Hai yếu tố phải được loại bỏ một lần và mãi mãi: nỗi sợ hãi về sự đau khổ của tương lai và ký ức về quá khứ đau khổ.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Cho đến khi chúng ta bắt đầu sống mà không có chúng, chúng ta không nhận ra một số thứ không cần thiết như thế nào.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Trở ngại lớn nhất xuất hiện cho chúng ta là sự kỳ vọng, nó phụ thuộc liên tục vào ngày mai, và mất đi mạnh mẽ ngày hôm nay.". - Seneca -`,
  },
  {
    id: 0,
    text: `"Không phải các sự kiện khiến ta phiền não, mà là cách ta nhìn nhận chúng.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Ngày tháng của bạn chỉ có hạn. Hãy sử dụng chúng để mở cánh cửa tâm hồn ra đón nắng mặt trời. Nếu không, mặt trời sẽ lặn, và bạn cũng thế.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Câu trả lời tốt nhất cho nỗi giận dữ là sự im lặng.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Không ai có thể đánh mất dù là quá khứ hay tương lai - làm sao con người có thể đánh mất thứ mà anh ta không có? ... Chỉ có khoảng khắc hiện tại có thể bị cướp đi: và nếu đây là tất cả những gì anh ta có, thì còn gì khác có thể bị đánh mất nữa đâu.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Giá trị của một người được đo bằng giá trị của điều mà anh ta trân trọng.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Con người không nên sợ hãi cái chết, mà nên sợ hãi mình chưa bao giờ bắt đầu sống.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Mọi điều ta nghe thấy đều chỉ là một quan điểm, không phải là sự thật. Mọi thứ ta nhìn thấy đều chỉ là một góc nhìn, không phải là sự thật.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Nếu bạn phiền não bởi bất cứ sự vật sự việc bên ngoài nào, đau đớn không phải là vì chính bản thân sự vật sự việc đó, mà là cách bạn đánh giá nó; và bạn có quyền năng rút lại đánh giá bất cứ lúc nào.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Sự trả thù tốt nhất là đừng giống như kẻ gây ra vết thương.". - Marcus -`,
  },

  {
    id: 0,
    text: `"Con người tiết kiệm được biết bao nhiêu thời gian khi không mải nhìn người bên cạnh làm gì, nói gì, hay nghĩ gì.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Những điều mà bạn suy nghĩ quyết định phẩm chất của tâm trí bạn.". - Marcus -`,
  },

  {
    id: 0,
    text: `"Khi bạn thức dậy vào buổi sáng, hãy nghĩ xem đó là một đặc ân để được sống, được nghĩ, được tận hưởng, được yêu…". - Marcus -`,
  },
  {
    id: 0,
    text: `"Hạnh phúc trong cuộc sống của bạn phụ thuộc vào chất lượng những suy nghĩ của bạn.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Hãy làm mọi hành động trong cuộc sống của bạn như thể đó là hành động cuối cùng của cuộc đời bạn.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Chính người tiếp tục tự lừa dối bản thân và giữ sự thiếu hiểu biết của mình mới là kẻ bị hại.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Giá trị của một người không lớn hơn giá trị của những tham vọng của anh ta". - Marcus -`,
  },
  {
    id: 0,
    text: `"Tôi thường tự hỏi làm thế nào mà một người yêu bản thân mình hơn tất cả những người còn lại, nhưng lại ít coi trọng ý kiến ​​của mình hơn là quan điểm của người khác.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Tất cả mọi thứ mờ dần và nhanh chóng chuyển sang thần thoại.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Hãy tự giam mình vào hiện tại.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Hãy đào sâu bên trong - bên trong là dòng dõi của điều tốt đẹp; và nó luôn sẵn sàng nổi lên, nếu bạn chỉ cần đào.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Đừng xấu hổ khi được giúp đỡ.". - Marcus -`,
  },
  {
    id: 0,
    text: `"Hoàn cảnh không tạo nên người đàn ông, họ chỉ bộc lộ anh ta với chính mình.". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Đừng nói về bất cứ điều gì ‘Tôi đã đánh mất nó’, mà hãy nói ‘Tôi đã trả lại nó.’". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Đừng giải thích triết lý của bạn. Hiện thân nó.". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Nếu bạn muốn trở nên tốt, trước tiên hãy tin rằng bạn xấu.". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Khi được hỏi, ‘Ai là người giàu?’ Epictetus trả lời, ‘Anh ấy là người bằng lòng.’ ". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Tuân theo các nguyên tắc của bạn như thể chúng là luật. Đừng lo lắng nếu người khác chỉ trích hoặc cười nhạo bạn, vì ý kiến ​​của họ không phải là mối quan tâm của bạn". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Nếu bạn làm hòa với tất cả những thứ nằm ngoài khả năng của mình, từ chối chống lại chúng, bạn sẽ là kẻ bất khả chiến bại.". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Người hay cười với chính mình thì không bao giờ hết chuyện để cười. ". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Liên tục nhắc nhở bản thân rằng bạn là một sinh vật phàm trần, và một ngày nào đó sẽ chết.". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Nếu điều ác được nói về bạn, và nếu điều đó là sự thật, hãy sửa chính mình; nếu nó là một lời nói dối, hãy cười nó. ". - Epictetus -`,
  },
  {
    id: 0,
    text: `"
      Ngài là người khôn ngoan, không đau buồn vì những điều mình không có, nhưng vui mừng vì những điều mình có.". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Trong thời thịnh vượng, rất dễ dàng tìm thấy một người bạn; nhưng trong nghịch cảnh, đó là khó khăn nhất trong tất cả mọi thứ.". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Chăm sóc cơ thể khi cần thiết, nhưng hãy dành nguồn năng lượng và nỗ lực chính vào việc trau dồi tâm trí. ". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Tự do được bảo đảm không phải bằng việc đáp ứng các ham muốn, mà bằng việc loại bỏ ham muốn.". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Đau ốm nhưng hạnh phúc, trong nguy hiểm nhưng hạnh phúc, chết nhưng vẫn hạnh phúc, lưu vong và hạnh phúc, trong ô nhục vẫn hạnh phúc". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Không ai là không hạnh phúc vì người khác". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Cuộc sống là một bản nhạc, và bạn phải nhảy.". - Epictetus -`,
  },

  {
    id: 0,
    text: `"Vậy thì cái gì là của riêng bạn? Cách bạn sống cuộc sống của bạn.". - Epictetus -`,
  },
  {
    id: 0,
    text: `"Trí tuệ bắt nguồn từ những điều kì diệu.". - Socrates -`,
  },
  {
    id: 0,
    text: `"Hiểu biết đích thực là biết rằng mình không biết gì cả.". - Socrates -`,
  },
  {
    id: 0,
    text: `"Bạn sẽ trở thành người mà bạn muốn.". - Socrates -`,
  },
  {
    id: 0,
    text: `"Giáo dục nhen nhóm ngọn lửa chứ không phải chỉ đổ đầy con tàu.". - Socrates -`,
  },
  {
    id: 0,
    text: `"Người không hài lòng với những gì anh ta có cũng sẽ không hài lòng với những gì anh ta sẽ có.". - Socrates -`,
  },
  {
    id: 0,
    text: `"Hãy thật chậm rãi khi bắt đầu 1 tình bạn nhưng một khi đã bắt đầu rồi, hãy nắm giữ thật chắc.

      ". - Socrates -`,
  },
  {
    id: 0,
    text: `"Đôi khi bạn dựng lên những bức tường không phải để ngăn cách với mọi người, mà để xem ai đủ quan tâm để phá vỡ nó". - Socrates -`,
  },
  {
    id: 0,
    text: `"Đừng đối xử với người khác theo cách mà bạn biết là bạn sẽ bực mình nếu người khác cũng đối xử với bạn như vậy.". - Socrates -`,
  },
  {
    id: 0,
    text: `"Lựa chọn kiến thức thay vì sự giàu có, với 1 số người chỉ là nhất thời, nhưng với 1 số người khác lại là mãi mãi.". - Socrates -`,
  },
  {
    id: 0,
    text: `Cái chết có lẽ là ân huệ lớn nhất của loài người."". - Socrates -`,
  },
];
