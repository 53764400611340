import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { InputAndLabel } from "components/Share/InputAndLabel";
import { useProject } from "hooks/useProject";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UseDisclosureProps } from "types/chakraUiProps";

type FormValues = {
  name: string;
};

export default function CreateProject({ isOpen, onOpen, onClose }: UseDisclosureProps) {
  const { register, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      name: "",
    },
    mode: "onBlur",
  });
  const { createProject } = useProject();
  const onSubmit = async (data: FormValues) => {
    if (!data.name) {
      return toast.error("Please fill a name!");
    }

    try {
      const res = await createProject(data);
      if (res) {
        toast.success("Created Successfully");
        onClose();
      } else {
        toast.error("Created Failed");
        onClose();
      }
    } catch (error) {
      toast.error("Created Failed");
    }
  };
  useEffect(() => {
    setValue("name", "");
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create A New Project</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <InputAndLabel label="Tên Dự án" {...register("name")} />
            </ModalBody>

            <ModalFooter>
              <Button type="submit" colorScheme="blue">
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
