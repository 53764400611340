import { createProjectApi, getListProjectsApi, updateProjectApi } from "api/projects";
import { useDispatch } from "react-redux";
import { createProjectStore, setListProject, updateListProject } from "store/modules/project/project";
import { CreateProjectItem, ProjectItem } from 'types/project';

export const useProject = () => {
    const dispatch = useDispatch()


    const getListProjectsData = async () => {

        const result = await getListProjectsApi();

        const data = result?.data

        if (data) {
            dispatch(setListProject(data));

            return true
        } else {
            return false
        }
    };

    const updateProject = async (payload: ProjectItem) => {

        const result = await updateProjectApi(payload);

        const data = result?.data

        if (data) {
            dispatch(updateListProject(data))
            return true
        } else return false

    };

    const createProject = async (payload: CreateProjectItem) => {

        const result = await createProjectApi(payload);

        const data = result?.data

        if (data) {
            dispatch(createProjectStore(data))
            return true
        } else return false

    };

    return {
        getListProjectsData,
        updateProject,
        createProject,
    };
};

