export const COLUMNS_TABLE_TRACKING_TIME = [
  "#",
  // "Screenshots",
  "Start",
  "End",
  "Project",
  "Total",
];

export const COLUMNS_TABLE_TRACKING_TIME_BY_DAY = ["#", "Date", "Total"];

export const COLUMNS_TABLE_TRACKING_TIME_BY_MONTH = ["#", "Month", "Total"];
