import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { LuxTechIcon } from "assets/images";
import { useAuth } from "hooks/useAuth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PasswordField } from "./PasswordField";
import { getErrorMsgResponse } from "../../utlis/formatError";
import { Mixpanel } from "utlis/mixpanel";

export const Main = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const { getLogin } = useAuth();

  const onSubmitLogin = async (data: any) => {
    try {
      const result = await getLogin(data);
      if (result) {
        Mixpanel.identify(data.email);
        toast.success("Login Success!");
        navigate("/");
      } else {
        Mixpanel.track('Login FAILED', data);
        toast.error("Error when login!");
      }
    } catch (error: any) {
      Mixpanel.track('Login FAILED', { ...data, error: getErrorMsgResponse(error) });
      toast.error(getErrorMsgResponse(error));
    }
  };
  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
      backgroundColor="white"
      borderRadius={{ base: "12px", sm: "24px" }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Box borderRadius="50%" w="80px" overflow="hidden" m="0 auto">
            <Image src={LuxTechIcon} w="100%" h="100%" objectFit="cover" />
          </Box>

          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
              Welcome to LuxTech
            </Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
          boxShadow={{ base: "none", sm: useColorModeValue("md", "md-dark") }}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          <form onSubmit={handleSubmit(onSubmitLogin)}>
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    {...register("email", { required: true })}
                    id="email"
                    type="email"
                  />
                </FormControl>
                <PasswordField {...register("password", { required: true })} />
              </Stack>
              <HStack justify="space-between">
                <Checkbox defaultChecked>Remember me</Checkbox>
                <Button variant="link" colorScheme="blue" size="sm">
                  Forgot password?
                </Button>
              </HStack>
              <Stack spacing="6">
                <Button
                  backgroundColor="blue.500"
                  _hover={{
                    opacity: "0.7",
                  }}
                  variant="primary"
                  // onClick={() => handleLogin()}
                  type="submit"
                >
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Container>
  );
};
