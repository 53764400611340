import { createSlice } from "@reduxjs/toolkit";
import { ReportState } from "./types";
// Slice

const initialState: ReportState = {
  listReportsToday: [],
  listReportsYesterday: [],

}
const slice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setListReportToday: (state, action) => {
      state.listReportsToday = action.payload
    },
    setListReportYesterday: (state, action) => {
      state.listReportsYesterday = action.payload
    },
    addListReportToday: (state, action) => {
      state.listReportsToday = [...state.listReportsToday, action.payload]
    },
    removeListReportToday: (state, action) => {
      state.listReportsToday = state.listReportsToday.filter((item) => item._id !== action.payload)
    },

  },
});
export default slice.reducer;
export const { setListReportToday, setListReportYesterday, addListReportToday, removeListReportToday } = slice.actions;