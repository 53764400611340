import moment from "moment";
import { TrackingTimeItem } from "types/trackingTime";

export const groupByDate = (array: TrackingTimeItem[]) =>
  array.reduce((results: TrackingTimeItem[], item: TrackingTimeItem) => {
    const current = results.find(
      (i: TrackingTimeItem) =>
        moment(i.createdAt).format("YYYY-MM-DD") ===
        moment(item.createdAt).format("YYYY-MM-DD")
    );
    if (current) {
      current["totalSeconds"] =
        current["totalSeconds"] + item["totalSeconds"];
    } else {
      results.push({ ...item });
    }
    return results;
  }, []);

export const groupByToday = (array: TrackingTimeItem[]) => {
  const tracks = groupByDate(array)
  const now = moment().format('YYYY-MM-DD')
  const getTrackingTimeToday = tracks.filter((item) => {
    if (moment(item.createdAt).format("YYYY-MM-DD") === now) {
      return item
    } else return null
  })
  return getTrackingTimeToday
};
