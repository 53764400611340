import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import auth from './modules/auth/auth'
import project from './modules/project/project'
import monthManage from './modules/monthManage/monthManage'
import report from './modules/report/report'
import trackingTime from './modules/trackingTime/trackingTime'
import user from './modules/user/user'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { getDefaultMiddleware } from '@reduxjs/toolkit';

const reducers = combineReducers({
    auth,
    trackingTime,
    user,
    project,
    monthManage,
    report,
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
};

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: customizedMiddleware,
})

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>