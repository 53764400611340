import AuthLayout from "layouts/Auth";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { selectors } from "store/seletors";
import { RouteProps } from "types";
import { AUTH_ROUTES, GENERAL_ROUTES } from "./index";

export const RenderRoutes = () => {
  const PrivateRoute = ({
    layout: YourLayout,
    children,
  }: {
    layout: any;
    children: JSX.Element;
  }) => {
    //TODO: User is not logged in => navigate login page
    const isAuth = useSelector(selectors.auth.getIsAuth);

    return isAuth ? (
      <YourLayout>{children}</YourLayout>
    ) : (
      <Navigate to="/login" />
    );
  };

  const PublicRoute = ({ children }: { children: JSX.Element }) => {
    //TODO: User login, refresh page => navigate login page
    const isAuth = useSelector(selectors.auth.getIsAuth);

    return isAuth ? <Navigate to="/" /> : children;
  };

  const renderLoggedRoutes = useCallback(() => {
    return GENERAL_ROUTES?.map((route: RouteProps) => {
      return (
        <Route
          path={route.path}
          key={route.path}
          element={
            <PrivateRoute layout={route.layout}>{route.element()}</PrivateRoute>
          }
        />
      );
    });
  }, []);

  const renderAuthRoutes = useCallback(() => {
    return AUTH_ROUTES?.map((route: RouteProps) => {
      return (
        <Route
          path={route.path}
          key={route.path}
          element={
            <PublicRoute>
              <AuthLayout>{route.element()}</AuthLayout>
            </PublicRoute>
          }
        />
      );
    });
  }, []);

  return (
    <Routes>
      {renderLoggedRoutes()}
      {renderAuthRoutes()}
    </Routes>
  );
};
