import { useNavigatorOnLine } from "hooks/useNavigatorOnLine";
import { useLayoutEffect, useRef } from "react";

export const NetworkStatusIndicator = () => {
  const isOnline = useNavigatorOnLine();
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    // isOnline
    //   ? alert('You are back online!')
    //   : alert('You are currently offline');
    window.location.reload();// reload on network changes
  }, [isOnline]);

  return null;
};