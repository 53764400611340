import {
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
} from "@chakra-ui/react";
import {
  COLUMNS_TABLE_REPORTS_BY_DAY,
  COLUMNS_TABLE_REPORTS_BY_DAY_MONTHS,
} from "constants/reports";
import { ReportItem } from "types/report";
import { UserItem } from "types/user";
import { toDateDay, toMonthAndYear } from "utlis/formatTime";

type Props = {
  listReportsEveryDay: ReportItem[];
  selectedEmployee?: UserItem;
};

export default function TabsReports({
  listReportsEveryDay,
  selectedEmployee,
}: Props) {
  const listReportsEveryDayByEmployee = listReportsEveryDay.filter(
    (item) => item.email === selectedEmployee?.email
  );

  return (
    <>
      <Box h="200vh">
        <Tabs
          variant="soft-rounded"
          colorScheme="whatsapp"
          mt="40px"
          mb="100px"
        >
          <TabList justifyContent="center">
            <Tab>Theo Ngày</Tab>
            <Tab>Theo Tháng</Tab>
          </TabList>

          {/* Theo ngày */}
          <TabPanels>
            <TabPanel>
              <TableContainer>
                <Table variant="striped" colorScheme="blue">
                  <Thead>
                    <Tr>
                      {COLUMNS_TABLE_REPORTS_BY_DAY.map((item, idx) => (
                        <Th key={idx}>{item}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <>
                    <Tbody>
                      {listReportsEveryDayByEmployee.length > 0 ? (
                        listReportsEveryDayByEmployee.map((item, idx) => {
                          return (
                            <Tr key={item._id}>
                              <Td>{toDateDay(item.createdAt)}</Td>
                              <Td>{item.name}</Td>
                              <Td>{item.labels?.[0]}</Td>
                              <Td>{item.description?.[0]}</Td>
                            </Tr>
                          );
                        })
                      ) : (
                        <Tr>
                          <Td>No data</Td>
                          <Td>No data</Td>
                          <Td>No data</Td>
                          <Td>No data</Td>
                        </Tr>
                      )}
                    </Tbody>
                  </>
                </Table>
              </TableContainer>
            </TabPanel>

            {/* Theo tháng */}
            <TabPanel>
              <TableContainer>
                <Table variant="striped" colorScheme="blue">
                  <Thead>
                    <Tr>
                      {COLUMNS_TABLE_REPORTS_BY_DAY_MONTHS.map((item, idx) => (
                        <Th key={idx}>{item}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <>
                    <Tbody>
                      {listReportsEveryDayByEmployee.length > 0 ? (
                        <Tr>
                          <Td>
                            {toMonthAndYear(
                              listReportsEveryDayByEmployee[0].createdAt
                            )}
                          </Td>
                          <Td>{listReportsEveryDayByEmployee[0].name}</Td>
                          <Td>{listReportsEveryDayByEmployee.length}</Td>
                          <Td>{22 - listReportsEveryDayByEmployee.length}</Td>
                        </Tr>
                      ) : (
                        <Tr>
                          <Td>No data</Td>
                          <Td>No data</Td>
                          <Td>No data</Td>
                          <Td>No data</Td>
                        </Tr>
                      )}
                    </Tbody>
                  </>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
