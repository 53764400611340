import {
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  IconButton,
  Img,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { LuxTechIcon } from "assets/images";
import {
  DEFAULT_SIDEBARS_ADMIN,
  DEFAULT_SIDEBARS_MEMBER,
} from "constants/sidebars";
import { ReactNode } from "react";
import { IconType } from "react-icons";
import { FiMenu } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectors } from "store/seletors";

export default function Sidebar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" w="100%">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="0">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const user = useSelector(selectors.auth.getUserInfo);
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <LogoComponent />

        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>

      {user.role === "admin"
        ? DEFAULT_SIDEBARS_ADMIN.map((item) => {
          return (
            <NavItem
              key={item.id}
              link={item.path}
              icon={item.icon}
              onClose={onClose}
            >
              {item.title}
            </NavItem>
          );
        })
        : DEFAULT_SIDEBARS_MEMBER.map((item) => {
          return (
            <NavItem
              key={item.id}
              link={item.path}
              icon={item.icon}
              onClose={onClose}
            >
              {item.title}
            </NavItem>
          );
        })}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  link: string;
  onClose: () => void;
  children: ReactNode;
}
const NavItem = ({ icon, link, onClose, children, ...rest }: NavItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  return (
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      {...rest}
      backgroundColor={pathname.toString() === link ? "green.500" : "white"}
      my="12px"
      onClick={() => {
        navigate(link);
        onClose();
      }}
    >
      <Text>{children}</Text>
    </Flex>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="center"
      pos="relative"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
        pos="absolute"
        top="50%"
        transform="translateY(-50%)"
        left="16px"
      />
      <LogoComponent />
    </Flex>
  );
};

const LogoComponent = () => {
  return (
    <Flex alignItems="center">
      <Box w="24px" h="24px" mr="12px" borderRadius="50%" overflow="hidden">
        <Img
          w="100%"
          h="100%"
          src={LuxTechIcon}
          alt="Logo Mavia"
          objectFit="cover"
        />
      </Box>
      <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
        LuxTech
      </Text>
    </Flex>
  );
};
