import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "./types";
// Slice

const initialState: UserState = {
  listUsers: []

}
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setListUser: (state, action) => {
      state.listUsers = action.payload;
    },

  },
});
export default slice.reducer;
export const { setListUser } = slice.actions;