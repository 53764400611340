import { Td, Tr } from "@chakra-ui/react";

const NoDataFields = ({ numberOfField }: { numberOfField: number }) => {
  return (
    <Tr>
      {Array.from({ length: numberOfField || 1 }).map((i, key) => {
        return <Td key={key}>No Data</Td>;
      })}
    </Tr>
  );
};
export default NoDataFields;
