import {
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { InputAndLabel } from "components/Share/InputAndLabel";
import { useUser } from "hooks/useUser";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UserItem } from "types/user";

type Props = {
  item: UserItem;
  isOpen: boolean;
  onClose: () => void;
};

type FormValues = {
  name: string;
  email: string;
  joinDate?: string;
  position?: string;
  nameBank?: string;
  accountBank?: string;
  avatarUrl?: string;
  allowedWebTracking?: boolean
  allowedWorkAtWeekend?: boolean
  allowedManualLog?: boolean
  allowedOT?: boolean
  isOff?: boolean
  isWorkLate?: boolean
  isWFH?: boolean
  allowedWorkOver7h30m?: boolean
  skipScreenshot?: boolean
  screenshotIntervalInMinute?: number
  deactivated?: boolean
};

export default function EditUser({ item, isOpen, onClose }: Props) {
  const { register, setValue, handleSubmit } = useForm<FormValues>({
    mode: "onBlur",
  });

  const {
    name,
    joinDate = "",
    position = "",
    avatarUrl = "",
    email,
    allowedWebTracking = false,
    allowedWorkAtWeekend = false,
    allowedManualLog = false,
    allowedOT = false,
    isOff = false,
    isWorkLate = false,
    isWFH = false,
    allowedWorkOver7h30m = false,
    skipScreenshot = false,
    screenshotIntervalInMinute = 5,
    deactivated = false
  } = item;

  const { adminUpdateUser } = useUser();
  const onSubmit = async (data: FormValues) => {
    const payload = { ...data, id: item._id };
    try {
      const res = await adminUpdateUser(payload);
      if (res) {
        toast.success("Edit Successfully");
        onClose();
      } else {
        toast.error("Edit Failed");
        onClose();
      }
    } catch (error) {
      toast.error("Edit Failed");
    }
  };

  useEffect(() => {
    if (item) {
      setValue("name", name);
      setValue("email", email);
      setValue("joinDate", joinDate);
      setValue("position", position);
      setValue("avatarUrl", avatarUrl);
      setValue("allowedWebTracking", allowedWebTracking);
      setValue("allowedWorkAtWeekend", allowedWorkAtWeekend);
      setValue("allowedManualLog", allowedManualLog);
      setValue("allowedOT", allowedOT);
      setValue("isOff", isOff);
      setValue("isWorkLate", isWorkLate);
      setValue("isWFH", isWFH);
      setValue("allowedWorkOver7h30m", allowedWorkOver7h30m);
      setValue("skipScreenshot", skipScreenshot);
      setValue("screenshotIntervalInMinute", screenshotIntervalInMinute);
      setValue("deactivated", deactivated);
    }
  }, [allowedManualLog, allowedOT, allowedWebTracking, allowedWorkAtWeekend, allowedWorkOver7h30m, avatarUrl, deactivated, email, isOff, isWFH, isWorkLate, item, joinDate, name, position, screenshotIntervalInMinute, setValue, skipScreenshot]);

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Edit {item?.name}</DrawerHeader>

            <DrawerBody>
              <InputAndLabel label="1. Name" {...register("name")} />
              <InputAndLabel label="2. Email" {...register("email")} />
              <InputAndLabel label="3. Joined date" {...register("joinDate")} />
              <InputAndLabel label="4. Position" {...register("position")} />
              <InputAndLabel
                label="5. Avatar image url"
                {...register("avatarUrl")}
              />
              <InputAndLabel
                label="6. Screenshot interval in minute"
                {...register("screenshotIntervalInMinute")}
              />
              <Checkbox defaultChecked={allowedWebTracking} onChange={(e: any) => setValue("allowedWebTracking", e?.target?.checked)}>Allow web tracking</Checkbox>
              <Checkbox defaultChecked={allowedOT} onChange={(e: any) => setValue("allowedOT", e?.target?.checked)}>Allow OT</Checkbox>
              <Checkbox defaultChecked={isOff} onChange={(e: any) => setValue("isOff", e?.target?.checked)}>Off today</Checkbox>
              <Checkbox defaultChecked={isWorkLate} onChange={(e: any) => setValue("isWorkLate", e?.target?.checked)}>Work late today</Checkbox>
              <Checkbox defaultChecked={isWFH} onChange={(e: any) => setValue("isWFH", e?.target?.checked)}>WFH today</Checkbox>
              <Checkbox defaultChecked={allowedWorkAtWeekend} onChange={(e: any) => setValue("allowedWorkAtWeekend", e?.target?.checked)}>Allow work at weekend</Checkbox>
              <Checkbox defaultChecked={allowedManualLog} onChange={(e: any) => setValue("allowedManualLog", e?.target?.checked)}>Allow manual log</Checkbox>
              <Checkbox defaultChecked={allowedWorkOver7h30m} onChange={(e: any) => setValue("allowedWorkOver7h30m", e?.target?.checked)}>Allow over 7h30m</Checkbox>
              <Checkbox defaultChecked={skipScreenshot} onChange={(e: any) => setValue("skipScreenshot", e?.target?.checked)}>Skip screenshot</Checkbox>
              <Checkbox defaultChecked={deactivated} onChange={(e: any) => setValue("deactivated", e?.target?.checked)}>Deactivated</Checkbox>
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" type="submit">
                Save
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    </>
  );
}
