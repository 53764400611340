import { createSlice } from "@reduxjs/toolkit";
import { TrackingTimeState } from "./types";
// Slice

const initialState: TrackingTimeState = {
  isWorking: false,
  trackingTimeCurrent: {
    totalSeconds: 0,
    userId: "",
    projectId: "",
    _id: "",
  },
  listAllTrackingTimeByOrder: [],
  userSelectedByAdmin: {
    _id: "",
    name: "",
    email: "",
    role: "",
    isWorking: false,
  },
};
const slice = createSlice({
  name: "trackingTime",
  initialState,
  reducers: {
    setIsWorking: (state, action) => {
      state.isWorking = action.payload;
    },
    setTrackingTimeCurrent: (state, action) => {
      state.trackingTimeCurrent = action.payload;
    },

    setListAllTrackingTimeByOrder: (state, action) => {
      state.listAllTrackingTimeByOrder = action.payload;
    },
    setUserSelectedByAdmin: (state, action) => {
      state.userSelectedByAdmin = action.payload;
    },
  },
});
export default slice.reducer;
export const {
  setIsWorking,
  setTrackingTimeCurrent,
  setListAllTrackingTimeByOrder,
  setUserSelectedByAdmin,
} = slice.actions;
