import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

type BaseModalProps = {
  title: string;
  desc: string;
  isOpen: boolean;
  onClose: () => void;
  textBtn: string;
  handleBtn?: () => void;
  children?: JSX.Element;
};

export default function BaseModal({
  title,
  desc,
  textBtn,
  isOpen,
  children,
  onClose,
  handleBtn,
}: BaseModalProps) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>{desc}</Box>
            {children}
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button> */}
            {handleBtn && (
              <Button backgroundColor="blue.500" onClick={handleBtn}>
                {textBtn}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
