import GeneralLayout from "layouts/General";
import EmptyLayout from "layouts/Empty";
import ClientsPage from "pages/Clients";
import TrackingTimePage from "pages/TrackingTime";
import UserPage from "pages/Employees";
import ReportPage from "pages/Reports";
import LoginPage from "pages/Login";
import ProjectsPage from "pages/Projects";
import DashboardPage from "pages/Dashboard";
import ProfilesPage from "pages/Profiles";
import DestinyPage from "pages/Destiny";
import Screenshots from "pages/Screenshots";

export const GENERAL_ROUTES = [
  {
    path: "/employees",
    name: "Employees",
    element: UserPage,
    layout: GeneralLayout,
  },
  {
    path: "/reports",
    name: "Reports",
    element: ReportPage,
    layout: GeneralLayout,
  },
  {
    path: "/profile",
    name: "Profile",
    element: ProfilesPage,
    layout: GeneralLayout,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    element: DashboardPage,
    layout: GeneralLayout,
  },
  {
    path: "/",
    name: "TrackingTime",
    element: TrackingTimePage,
    layout: GeneralLayout,
  },
  {
    path: "/projects",
    name: "Projects",
    element: ProjectsPage,
    layout: GeneralLayout,
  },
  {
    path: "/clients",
    name: "Clients",
    element: ClientsPage,
    layout: GeneralLayout,
  },
  {
    path: "/destiny",
    name: "Destiny",
    element: DestinyPage,
    layout: GeneralLayout,
  },
  {
    path: "/screenshots",
    name: "Screenshots",
    element: Screenshots,
    layout: EmptyLayout,
  },
];

export const AUTH_ROUTES = [
  {
    path: "/login",
    name: "Login",
    element: LoginPage,
    layout: GeneralLayout,
  },
];
