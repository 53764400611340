import { createSlice } from "@reduxjs/toolkit";
import { MonthManageState } from "./types";
// Slice

const initialState: MonthManageState = {
  monthManage: {
    tables: [],
    date: '',
  },
  createdMonths: []

}
const slice = createSlice({
  name: "monthManage",
  initialState,
  reducers: {
    setMonthManage: (state, action) => {
      state.monthManage = action.payload;
    },
    setCreatedMonths: (state, action) => {
      state.createdMonths = action.payload;
    },
  },
});
export default slice.reducer;
export const { setMonthManage, setCreatedMonths } = slice.actions;