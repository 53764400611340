import { Button, Flex, Text } from "@chakra-ui/react";
import { DATA_CONTENT } from "constants/fakeData";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutSuccess } from "store/modules/auth/auth";
import { NetworkStatusIndicator } from "./NetworkStatusIndicator";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [text, setText] = useState(DATA_CONTENT[Math.floor(Math.random() * (DATA_CONTENT.length - 1)) + 1].text);
  // const { updateMe } = useAuth();
  // const user = useSelector(selectors.auth.getUserInfo);

  const handleLogout = () => {
    // localStorage.clear();// Don't do this, we have other items also
    localStorage.removeItem('lt-jwt')
    localStorage.removeItem('currentUser')
    dispatch(logoutSuccess());
    navigate("/login");
  };

  const randomText = () => {
    const numberRandom =
      Math.floor(Math.random() * (DATA_CONTENT.length - 1)) + 1;
    setText(DATA_CONTENT[numberRandom].text);
  };

  // const updateLastTrack = useCallback(async () => {
  //   try {
  //     if (user?.isWorking) {
  //       await updateMe({ lastTrack: new Date() })
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [updateMe, user?.isWorking])

  // useEffect(() => {
  //   const intervalCall = setInterval(() => {
  //     updateLastTrack();
  //   }, 60000); //every 1 minute

  //   return () => {
  //     // clean up
  //     clearInterval(intervalCall);
  //   };
  // }, [updateLastTrack, user?.isWorking]);

  return (
    <>
      <Flex className="navbar" p="16px 24px 0" justify="space-between">
        <Text fontSize="lg" fontWeight="700">
          LuxTrack
        </Text>
        <Button onClick={() => handleLogout()} colorScheme="red">
          Logout
        </Button>
      </Flex>
      <NetworkStatusIndicator />
      <Text p="16px 24px" fontSize="md" fontWeight="700" color="blue.500">
        {text}
      </Text>
      <Button mt="12px" mb="40px" onClick={() => randomText()}>
        Chọn câu ngẫu hứng cho ngày mới
      </Button>
    </>
  );
}
