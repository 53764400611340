import { CreateProjectItem, ProjectItem } from 'types/project';
import baseService from 'api/baseService'
import { AxiosResponse } from 'axios'
import { ProjectsResponseData } from './types'

export const getListProjectsApi = (): Promise<AxiosResponse<ProjectsResponseData>> =>
    baseService.get(`project`)

export const updateProjectApi = (payload: ProjectItem): Promise<AxiosResponse<ProjectItem>> =>
    baseService.patch(`project/${payload._id}`, payload)

export const createProjectApi = (payload: CreateProjectItem): Promise<AxiosResponse<ProjectItem>> =>
    baseService.post(`project`, payload)



