import {
    Box,
    Button,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Table,
    TableContainer,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure
} from "@chakra-ui/react";
import { InputAndLabel } from "components/Share/InputAndLabel";
import BaseModal from "components/Share/Modal";
import NoDataFields from "components/noDataFileds";
import {
    COLUMNS_TABLE_TRACKING_TIME,
    COLUMNS_TABLE_TRACKING_TIME_BY_DAY,
    COLUMNS_TABLE_TRACKING_TIME_BY_MONTH,
} from "constants/trackingTime";
import { userTrackingTime } from "hooks/useTrackingTime";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { selectors } from "store/seletors";
import { TrackingTimeItem } from "types/trackingTime";
import { UserItem } from "types/user";
import {
    toDateTime,
    toDateDay,
    toHHMMSS,
    toNumOfHrs,
    toMonthAndYear,
} from "utlis/formatTime";
import { groupByDate } from "utlis/groupByDate";
import { groupByMonth } from "utlis/groupByMonth";

type Props = {
    listTrackingTimeByOrder: TrackingTimeItem[];
    userSelected?: UserItem;
};

type FormValues = {
    hours: number;
    minutes: number;
};

export default function TabsTracking({
    listTrackingTimeByOrder,
    userSelected,
}: Props) {
    const seconds7h30m = 7 * 60 * 60 + 30 * 60
    const user = useSelector(selectors.auth.getUserInfo);
    const projectsLuxTech = useSelector(selectors.project.getListProjects);
    const [selectedItem, setSelectedItem] = useState<TrackingTimeItem>();

    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure();

    const {
        isOpen: isOpenEdit,
        onOpen: onOpenEdit,
        onClose: onCloseEdit,
    } = useDisclosure();

    const { register, handleSubmit } = useForm<FormValues>({
        mode: "onBlur",
    });

    const { editTrackingTime, deleteTrackingTime } = userTrackingTime();

    const handleDelete = async () => {
        if (selectedItem) {
            const res = await deleteTrackingTime(selectedItem?._id);

            if (res) {
                toast.success("Success!");
                onCloseDelete();
            } else {
                toast.error("Error when delete tracking Time!");
                onCloseDelete();
            }
        }
    };

    const onSubmit = async (data: FormValues) => {
        const hoursToSeconds = data.hours * 60 * 60;
        const minutesToSeconds = data.minutes * 60;
        const totalSeconds = hoursToSeconds + minutesToSeconds;

        if (selectedItem) {
            const payload = {
                trackingId: selectedItem?._id,
                totalSeconds: totalSeconds,
            };

            const res = await editTrackingTime(payload);

            if (res) {
                toast.success("Success!");
                onCloseEdit();
            } else {
                toast.error("Error when edit tracking Time!");
                onCloseEdit();
            }
        }
    };

    return (
        <>
            {user.role === 'admin' && (
                <Box color='blue.500' fontWeight={'bold'}>
                    {userSelected?.name
                        ? `Bạn đang xem thông tin tracking time của ${userSelected.name}`
                        : 'Chọn nhân viên mà bạn muốn xem thông tin tracking time'}
                </Box>
            )}
            <Tabs variant='soft-rounded' colorScheme='whatsapp' mt='20px' mb='100px'>
                <TabList justifyContent='center'>
                    <Tab>By Order</Tab>
                    <Tab>By Date</Tab>
                    <Tab>By Month</Tab>
                </TabList>

                {/* Theo thứ tự */}
                <TabPanels>
                    <TabPanel>
                        <TableContainer maxHeight='700px' overflowY='auto'>
                            <Table variant='striped' colorScheme='blue'>
                                <Thead>
                                    <Tr>
                                        {COLUMNS_TABLE_TRACKING_TIME.map((item, idx) => (
                                            <Th key={idx}>{item}</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <>
                                    <Tbody>
                                        {listTrackingTimeByOrder.length > 0 ? (
                                            listTrackingTimeByOrder.map((item, idx) => {
                                                const nameProject =
                                                    projectsLuxTech.find((project) => project._id === item?.projectId)?.name ?? 'No Project';
                                                // https://stackoverflow.com/questions/2013255/how-to-get-year-month-day-from-a-date-object
                                                // const timestamp = `${new Date(item.createdAt).toLocaleDateString('en-ZA')}`;
                                                // const prefix = user.role === 'admin' && userSelected ? `${userSelected.email}/${timestamp}/${item._id}` : `${user.email}/${timestamp}/${item._id}`
                                                return (
                                                    <Tr key={item._id} style={{ color: item.isManualLog ? 'blue' : 'black' }}>
                                                        <Td>{idx + 1}</Td>
                                                        {/* <Td
                                                            cursor='pointer'
                                                        >
                                                            <Link to={`/screenshots?prefix=${prefix}`}><Button colorScheme="green">View</Button></Link>
                                                        </Td> */}
                                                        <Td>{toDateTime(item.createdAt)}</Td>
                                                        <Td>{toDateTime(item.updatedAt)}</Td>
                                                        <Td>{nameProject}</Td>
                                                        <Td>{toHHMMSS(item.totalSeconds)}</Td>
                                                        {user.role === 'admin' && (
                                                            <>
                                                                <Td
                                                                    cursor='pointer'
                                                                    onClick={() => {
                                                                        onOpenEdit();
                                                                        setSelectedItem(item);
                                                                    }}
                                                                >
                                                                    <Button colorScheme="blue">Edit</Button>
                                                                </Td>
                                                                <Td
                                                                    cursor='pointer'
                                                                    onClick={() => {
                                                                        onOpenDelete();
                                                                        setSelectedItem(item);
                                                                    }}
                                                                >
                                                                    <Button colorScheme="red">Delete</Button>
                                                                </Td>
                                                            </>
                                                        )}
                                                        <Td>{item.note}</Td>
                                                    </Tr>
                                                );
                                            })
                                        ) : (
                                            <NoDataFields numberOfField={6} />
                                        )}
                                    </Tbody>
                                </>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    {/* Theo ngày */}
                    <TabPanel>
                        <TableContainer maxHeight='700px' overflowY='auto'>
                            <Table variant='striped' colorScheme='blue'>
                                <Thead>
                                    <Tr>
                                        {COLUMNS_TABLE_TRACKING_TIME_BY_DAY.map((item, idx) => (
                                            <Th key={idx}>{item}</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <>
                                    <Tbody>
                                        {groupByDate(listTrackingTimeByOrder).length > 0 ? (
                                            groupByDate(listTrackingTimeByOrder).map((item, idx) => {
                                                return (
                                                    <Tr key={item._id}>
                                                        <Td>{idx + 1}</Td>
                                                        <Td>{toDateDay(item.createdAt)}</Td>
                                                        <Td>{item.totalSeconds >= seconds7h30m ? `${toHHMMSS(7.5 * 60 * 60)} ✅` : `${toHHMMSS(item.totalSeconds)} 👩‍💻`}</Td>
                                                        {user.role === 'admin' && <Td>{`Actual: ${toHHMMSS(item.totalSeconds)}`}</Td>}
                                                    </Tr>
                                                );
                                            })
                                        ) : (
                                            <NoDataFields numberOfField={3} />
                                        )}
                                    </Tbody>
                                </>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    {/* Theo tháng */}
                    <TabPanel>
                        <TableContainer maxHeight='700px' overflowY='auto'>
                            <Table variant='striped' colorScheme='blue'>
                                <Thead>
                                    <Tr>
                                        {COLUMNS_TABLE_TRACKING_TIME_BY_MONTH.map((item, idx) => (
                                            <Th key={idx}>{item}</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <>
                                    <Tbody>
                                        {groupByMonth(listTrackingTimeByOrder).length > 0 ? (
                                            groupByMonth(listTrackingTimeByOrder).map((item, idx) => {
                                                return (
                                                    <Tr key={item._id}>
                                                        <Td>{idx + 1}</Td>
                                                        <Td>{toMonthAndYear(item.createdAt)}</Td>
                                                        <Td>
                                                            {toHHMMSS(item.totalSeconds)} ({toNumOfHrs(item.totalSeconds)})
                                                        </Td>
                                                    </Tr>
                                                );
                                            })
                                        ) : (
                                            <NoDataFields numberOfField={3} />
                                        )}
                                    </Tbody>
                                </>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <BaseModal
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                title='Delete This Tracking Time'
                desc='Are you sure you wanna delete this?'
                textBtn='Delete'
                handleBtn={handleDelete}
            />
            <BaseModal
                isOpen={isOpenEdit}
                onClose={onCloseEdit}
                title='Edit This Tracking Time'
                desc={`Change Total Seconds ${toHHMMSS(Number(selectedItem?.totalSeconds))}`}
                textBtn='Delete'
            >
                <Box mt='24px'>
                    <Text mt='12px'>{ }</Text>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputAndLabel label='Total hours (min: 0, max: 7)' {...register('hours')} type={'number'} />
                        <InputAndLabel label='Total minutes (min: 0, max: 59)' {...register('minutes')} type={'number'} />

                        <Flex width="100%" justifyContent="right">
                            <Button
                                mt="30px"
                                colorScheme='blue' type='submit'
                            >
                                Submit
                            </Button>
                        </Flex>
                    </form>
                </Box>
            </BaseModal>
        </>
    );
}
