import { login } from "api/login";
import { updateMeApi } from "api/profiles";
import { useDispatch } from "react-redux";
import { loginSuccess } from "store/modules/auth/auth";
import { LoginForm } from "types/auth";
export const useAuth = () => {
  const dispatch = useDispatch();
  const getLogin = async (dataForm: LoginForm) => {
    const result = await login(dataForm);

    const data = result?.data;
    if (data) {
      dispatch(loginSuccess({ ...data }));
      return true;
    } else {
      return false;
    }
  };

  const updateMe = async (formData: any) => {
    const result = await updateMeApi(formData);

    const data = result?.data;
    if (data) {
      return true;
    } else {
      return false;
    }
  };

  return {
    getLogin,
    updateMe,
  };
};
