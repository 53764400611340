import { ReportByUser } from './../../types/report';
import baseService from "api/baseService";
import { AxiosResponse } from "axios";
import { ReportsResponseData } from "./types";

export const getAllReportByMonthApi = (): Promise<
  AxiosResponse<ReportsResponseData>
> => baseService.get(`report/month/all`);

export const getReportsToday = (): Promise<
  AxiosResponse<ReportsResponseData>
> => baseService.get(`report/today`);

export const getReportsYesterday = (): Promise<
  AxiosResponse<ReportsResponseData>
> => baseService.get(`report/yesterday`);

export const sendReport = (
  data: ReportByUser
): Promise<AxiosResponse> =>
  baseService.post(`report/`, data);

export const deleteReportByID = (
  id: string
): Promise<AxiosResponse> =>
  baseService.delete(`report/${id}`);
