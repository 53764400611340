
import * as auth from './auth'
import * as user from './user'
import * as project from './project'
import * as monthManage from './monthManage'
import * as report from './report'
import * as trackingTime from './trackingTime'

export const selectors = {
  auth,
  user,
  project,
  monthManage,
  report,
  trackingTime,
}
