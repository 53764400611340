import {
  AddTrackingTimeManually,
  EditTrackingTime,
} from "./../types/trackingTime";
import {
  addTrackingTimeManuallyApi,
  deleteTrackingTimeApi,
  editTrackingTimeApi,
  getAllTrackingTimeUserByOrderApi,
  // getTrackingTimeActiveOfAuth,
} from "api/trackingTime";
import { useDispatch } from "react-redux";
import {
  setListAllTrackingTimeByOrder,
  // setTrackingTimeCurrent,
} from "store/modules/trackingTime/trackingTime";

export const userTrackingTime = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  // const getTrackingTimeOfAuth = async (userId: string) => {
  //   // const result = await getTrackingTimeActiveOfAuth(userId);
  //   const data = result?.data;
  //   dispatch(setTrackingTimeCurrent(data));
  // };

  const getAllTrackingTimeUserByOrder = async (userId: string) => {
    const result = await getAllTrackingTimeUserByOrderApi(userId);
    const data = result?.data;
    const trackedTime = data.filter((track) => track.totalSeconds > 0);
    dispatch(setListAllTrackingTimeByOrder(trackedTime));
  };

  const addTrackingTimeManually = async (payload: AddTrackingTimeManually) => {
    const result = await addTrackingTimeManuallyApi(payload);
    const data = result?.data;
    if (data) {
      return true;
    } else {
      return false;
    }
  };

  const editTrackingTime = async (payload: EditTrackingTime) => {
    const result = await editTrackingTimeApi(payload);
    const data = result?.data;

    if (data) {
      return true;
    } else {
      return false;
    }
  };

  const deleteTrackingTime = async (id: string) => {
    const result = await deleteTrackingTimeApi(id);
    const data = result?.data;
    if (data) {
      return true;
    } else {
      return false;
    }
  };

  return {
    getAllTrackingTimeUserByOrder,
    // getTrackingTimeOfAuth,
    editTrackingTime,
    deleteTrackingTime,
    addTrackingTimeManually,
  };
};
