import * as React from "react";
import { Svg } from "../svg";

function SvgLogoMavia(props: { color?: string }) {
    return (
        <Svg width='24px' height='24px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <rect x='3.8797' y='4.85364' width='15.9843' height='14.03' fill='white' />
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M11.6391 23.407C18.0672 23.407 23.2781 18.2583 23.2781 11.907C23.2781 5.55569 18.0672 0.406982 11.6391 0.406982C5.21097 0.406982 0 5.55569 0 11.907C0 18.2583 5.21097 23.407 11.6391 23.407ZM8.53601 6.02146L11.6264 8.28913V8.30167L14.7168 6.04392V8.90521L11.6264 11.163L8.53601 8.89534V6.02146ZM8.53601 9.86076L11.6264 12.1284V12.141L14.7168 9.88321V9.86076L18.0799 7.32367V15.2941L14.7168 17.8312V12.7445L11.6264 15.0023L8.53601 12.7346V17.8312L5.17295 15.2941V7.32367L8.53601 9.86076Z'
                fill='url(#paint0_radial_10_7)'
            />
            <defs>
                <radialGradient
                    id='paint0_radial_10_7'
                    cx='0'
                    cy='0'
                    r='1'
                    gradientUnits='userSpaceOnUse'
                    gradientTransform='translate(11.6391 11.907) rotate(90) scale(11.5 11.6391)'
                >
                    <stop stop-color='#1C54C2' />
                    <stop offset='1' stop-color='#215DD6' />
                </radialGradient>
            </defs>
        </Svg>
    );
}

export default SvgLogoMavia;
