import moment from "moment";

export const toHHMMSS = (sec: number): string => {
  let sec_num = parseInt(String(sec), 10); // don't forget the second parm
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  let Ohours = hours + "";
  let Ominutes = minutes + "";
  let Oseconds = seconds + "";
  if (hours < 10) {
    Ohours = "0" + hours;
  }
  if (minutes < 10) {
    Ominutes = "0" + minutes;
  }
  if (seconds < 10) {
    Oseconds = "0" + seconds;
  }
  var time = Ohours + ":" + Ominutes + ":" + Oseconds;
  return time;
};

export const toDateTime = (value: string): string => {
  return moment(value).format("MMM Do - h:mm a");
};

export const toDateDay = (value: string): string => {
  return moment(value).format("MMMM Do");
};

export const toMonthAndYear = (value: string): string => {
  return moment(value).format("MMMM YYYY");
};

export const getMonth = (value: string): string => {
  const month = new Date(value).getMonth() + 1;
  return String(Number(month) >= 10 ? month : `0${month}`);
};

export const toNumOfHrs = (sec: number): string => {
  let sec_num = parseInt(String(sec), 10); // don't forget the second parm
  return Number(sec_num / 3600).toFixed(2);
};
