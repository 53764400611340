import { SvgDot } from "assets/icons";

export const DEFAULT_SIDEBARS_ADMIN = [
  {
    id: 0,
    title: "Tracking Time",
    path: "/",
    icon: SvgDot,
  },
  {
    id: 1,
    title: "Dashboard",
    path: "/dashboard",
    icon: SvgDot,
  },
  {
    id: 2,
    title: "Reports",
    path: "/reports",
    icon: SvgDot,
  },
  {
    id: 3,
    title: "Profile",
    path: "/profile",
    icon: SvgDot,
  },
  {
    id: 4,
    title: "Destiny",
    path: "/destiny",
    icon: SvgDot,
  },

  // {
  //   id: 3,
  //   title: "Projects",
  //   path: "/projects",
  //   icon: SvgDot,
  // },
  // {
  //   id: 4,
  //   title: "Employees",
  //   path: "/employees",
  //   icon: SvgDot,
  // },
  // {
  //   id: 5,
  //   title: "Clients",
  //   path: "/clients",
  //   icon: SvgDot,
  // },
];
export const DEFAULT_SIDEBARS_MEMBER = [
  {
    id: 0,
    title: "Tracking Time",
    path: "/",
    icon: SvgDot,
  },
  {
    id: 2,
    title: "Reports",
    path: "/reports",
    icon: SvgDot,
  },

  {
    id: 3,
    title: "Profile",
    path: "/profile",
    icon: SvgDot,
  },
  {
    id: 4,
    title: "Destiny",
    path: "/destiny",
    icon: SvgDot,
  },
];
