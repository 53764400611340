import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
} from "@chakra-ui/react";
import { InputAndLabel } from "components/Share/InputAndLabel";
import { useProject } from "hooks/useProject";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ProjectItem } from "types/project";

type Props = {
  item: ProjectItem;
  isOpen: boolean;
  onClose: () => void;
};

type FormValues = {
  name: string;
  description: string;
  status?: string;
  customer?: string;
  totalTrackingTime?: number;
  totalIncome?: number;
};

export default function EditProject({ item, isOpen, onClose }: Props) {
  const { register, setValue, handleSubmit } = useForm<FormValues>({
    mode: "onBlur",
  });

  const {
    name,
    description = "",
    status = "",
    customer = "",
    totalTrackingTime = 0,
    totalIncome = 0,
  } = item;

  const { updateProject } = useProject();

  const onSubmit = async (data: FormValues) => {
    const payload = { ...item, ...data };

    try {
      const res = await updateProject(payload);
      if (res) {
        toast.success("Edit Successfully");
        onClose();
      } else {
        toast.error("Edit Failed");
        onClose();
      }
    } catch (error) {
      toast.error("Edit Failed");
    }
  };

  useEffect(() => {
    if (item) {
      setValue("name", name);
      setValue("description", description);
      setValue("status", status);
      setValue("customer", customer);
      setValue("totalTrackingTime", totalTrackingTime);
      setValue("totalIncome", totalIncome);
    }
  }, [
    customer,
    description,
    item,
    name,
    setValue,
    status,
    totalIncome,
    totalTrackingTime,
  ]);

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Edit {item?.name}</DrawerHeader>

            <DrawerBody>
              <InputAndLabel label="Name" {...register("name")} />
              {/* <InputAndLabel
                label="2. Description"
                {...register("description")}
              />
              <InputAndLabel label="3. Customer" {...register("customer")} />
              <InputAndLabel
                label="4. Total Income (USD)"
                {...register("totalIncome")}
                type="number"
              />
              <InputAndLabel
                label="5. Total Tracking Time (Hours)"
                {...register("totalTrackingTime")}
                type="number"
                isReadOnly
              /> */}
              <Text fontSize="lg" fontWeight="700" mb="12px">
                Status
              </Text>
              <Select {...register("status")} placeholder="" mb="24px">
                {["Open", "Closed"].map((item, idx) => (
                  <option value={item} key={idx}>
                    {item}
                  </option>
                ))}
              </Select>
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" type="submit">
                Save
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    </>
  );
}
