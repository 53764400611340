import * as React from "react";
import { Svg } from "../svg";

function SvgDot(props: { color?: string }) {
    const { color = '#C4C4C4' } = props;

    return (
        <Svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <circle cx='6.5' cy='6.5' r='6.5' fill={color} />
        </Svg>
    );
}

export default SvgDot;
