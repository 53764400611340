import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  chakra,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { useListReports } from "hooks/useListReports";
import { useProject } from "hooks/useProject";
import { useUser } from "hooks/useUser";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "store/seletors";
import { UserItem } from "types/user";
import ReportCard from "./Card/Card";
import ReportModal from "./ReportModal/ReportModal";
import TabsReports from "./tabs";

export default function ListReports() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(selectors.auth.getUserInfo);
  const [selectedEmployee, setSelectedEmployee] = useState<UserItem>();

  const {
    reportsToday,
    reportsYesterday,
    haveEmployeesReportedToday,
    haveEmployeesReportedYesterday,
    userNotYetReportToday,
    userNotYetReportYesterday,
    employeesLuxTech,
    reportsByMonthState,
    getReportsDataToday,
    getReportsDataYesterday,
    getAllReportByMonth,
  } = useListReports();

  const { getListUsersData } = useUser();
  const { getListProjectsData } = useProject();

  useEffect(() => {
    getReportsDataToday();
    getReportsDataYesterday();
    getListUsersData();
    getListProjectsData();
    getAllReportByMonth();
  }, []);

  return (
    <>
      <Flex
        textAlign={"center"}
        pt={10}
        justifyContent={"center"}
        direction={"column"}
        width={"full"}
        mb="100px"
      >
        {user.role !== "admin" && (
          <Box width={{ base: "full", sm: "lg", lg: "xl" }} margin={"auto"}>
            <Button
              fontFamily={"Work Sans"}
              fontWeight={"bold"}
              fontSize={20}
              textTransform={"uppercase"}
              onClick={() => onOpen()}
              colorScheme="green"
              mb="12px"
              p="24px"
            >
              Add a report !
            </Button>
          </Box>
        )}

        <Box mt="40px" bgColor="purple.500" py="16px">
          <chakra.h2
            margin={"auto"}
            width={"70%"}
            fontFamily={"Inter"}
            fontWeight={"bold"}
            color="#fff"
            fontSize={"lg"}
          >
            Đã có{" "}
            <chakra.strong color="#000" fontSize="20px" mx="2px">
              {`${haveEmployeesReportedToday}/${employeesLuxTech.length}`}
            </chakra.strong>{" "}
            developer(s) report{" "}
            <chakra.strong color="#000" fontSize="20px" mx="2px">
              hôm nay
            </chakra.strong>
            !
          </chakra.h2>
          {userNotYetReportToday.length > 0 ? (
            <chakra.h3
              margin={"auto"}
              width={"70%"}
              fontFamily={"Inter"}
              fontWeight={"bold"}
              color="#fff"
              fontSize={"lg"}
            >
              {" "}
              Hôm nay có
              {userNotYetReportToday.map((user, idx) => {
                if (userNotYetReportToday.length === idx + 1) {
                  return <span key={user._id}> {user.name} </span>;
                }

                return <span key={user._id}> {user.name},</span>;
              })}
              chưa report
            </chakra.h3>
          ) : (
            <chakra.h3
              margin={"auto"}
              width={"70%"}
              fontFamily={"Inter"}
              fontWeight={"bold"}
              color="#fff"
              fontSize={"lg"}
            >
              Hãy trở thành người report đầu tiên trong ngày nào bro.
            </chakra.h3>
          )}
        </Box>
        <SimpleGrid
          columns={{ base: 1, xl: 3 }}
          spacing={"20"}
          mt={16}
          mx={"auto"}
          px="16px"
        >
          {reportsToday.map((cardInfo, index) => (
            <ReportCard isToday {...cardInfo} key={index} index={index} />
          ))}
        </SimpleGrid>

        <Box mt="40px" bgColor="purple.500" py="16px">
          <chakra.h2
            margin={"auto"}
            width={"70%"}
            fontFamily={"Inter"}
            fontWeight={"bold"}
            color="#fff"
            fontSize={"lg"}
          >
            Đã có{" "}
            <chakra.strong color="#000" fontSize="20px" mx="2px">
              {`${haveEmployeesReportedYesterday}/${employeesLuxTech.length}`}
            </chakra.strong>{" "}
            developer(s) report{" "}
            <chakra.strong color="#000" fontSize="20px" mx="2px">
              hôm qua
            </chakra.strong>
            !
          </chakra.h2>
          {userNotYetReportYesterday.length === 0 ? (
            <chakra.h3
              margin={"auto"}
              width={"70%"}
              fontFamily={"Inter"}
              fontWeight={"bold"}
              color="#fff"
              fontSize={"lg"}
            >
              Good job
            </chakra.h3>
          ) : (
            <chakra.h3
              margin={"auto"}
              width={"70%"}
              fontFamily={"Inter"}
              fontWeight={"bold"}
              color="#fff"
              fontSize={"lg"}
            >
              {" "}
              Hôm qua có
              {userNotYetReportYesterday.map((user, idx) => {
                if (userNotYetReportYesterday.length === idx + 1) {
                  return <span key={user._id}> {user.name} </span>;
                }

                return <span key={user._id}> {user.name},</span>;
              })}
              chưa report
            </chakra.h3>
          )}
        </Box>
        <SimpleGrid
          columns={{ base: 1, xl: 3 }}
          spacing={"20"}
          mt={16}
          mx={"auto"}
          px="16px"
        >
          {reportsYesterday.map((cardInfo, index) => (
            <ReportCard {...cardInfo} key={index} index={index} />
          ))}
        </SimpleGrid>
      </Flex>

      {user.role === "admin" &&
        reportsByMonthState &&
        reportsByMonthState?.length > 0 && (
          <>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Select Employee
              </MenuButton>
              <MenuList>
                {employeesLuxTech.map((item, idx) => (
                  <MenuItem
                    onClick={() => setSelectedEmployee(item)}
                    key={item._id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <TabsReports
              listReportsEveryDay={reportsByMonthState}
              selectedEmployee={selectedEmployee}
            />
          </>
        )}
      <ReportModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
}
