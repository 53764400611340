import { Avatar, Button, FormControl, Input } from '@chakra-ui/react';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectors } from 'store/seletors';

export default function ListProfiles() {
    const user = useSelector(selectors.auth.getUserInfo);
    const { register, handleSubmit } = useForm();
    const [avatarUrl, setAvatarUrl] = useState('');
    const { updateMe } = useAuth();

    const onSubmit = async (data: any) => {
        const updateAvatarUrl = String(data.avatarUrl);
        const checkHaveHttps = updateAvatarUrl.includes('https');
        if (checkHaveHttps) {
            const dataAvatar = {
                avatarUrl: String(data.avatarUrl),
            };
            const result = await updateMe(dataAvatar);
            if (result) {
                toast.success('Update Success!');
                setAvatarUrl(data.avatarUrl);
            } else {
                toast.error('Update falied!');
            }
        } else {
            toast.error('Please use a valid image URL!');
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl px='100px'>
                <Avatar src={avatarUrl === '' ? user.avatarUrl : avatarUrl} size={'2xl'} />
                <Input my='24px' {...register('avatarUrl')} border='3px solid blue' />
                <Button fontSize={'24px'} p='30px 60px' type='submit'>
                    Save
                </Button>
            </FormControl>
        </form>
    );
}
