import { Box, BoxProps } from "@chakra-ui/react";

interface Props {
  viewBox?: string;
  xmlns?: string;
  xlinkHref?: string;
  xmlnsXlink?: string;
}

export const Svg = (props: BoxProps & Props) => <Box as="svg" {...props} />;
