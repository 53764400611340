import { AddIcon, DownloadIcon } from '@chakra-ui/icons';
import {
  Avatar, Box, Button, Flex, Icon, Spacer, Tag, Text, useDisclosure
} from "@chakra-ui/react";
import AddClientModal from "./AddClientModal/AddClientModal";
import "./styles.scss";


export default function ListClients() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
    <Flex h="100vh">
        <Box w="100%" >
          <Box className="client mg">
              <Flex className="clientHeader" gap='2' minWidth='max-content'>
                <Box className="label">
                  <Text color="#1B242F">Clients</Text>
                </Box>
                <Spacer />
                <Flex className="button" alignItems='center' gap='2'>
                    <Box className="dowloadIcon">
                      <Button>
                        <Icon as={DownloadIcon} />
                      </Button>
                    </Box>
                    <Box>
                      <Button 
                      className="buttonAdd" 
                      p="4" 
                      bg='#8B8BE6'
                      onClick={() => onOpen()}
                      >
                        <Icon className="addIcon" as={AddIcon} />Add client
                      </Button>
                    </Box>
                </Flex>
              </Flex>
          </Box>
          <Box className="main-table mg">
            <Flex className="table-header">
              <Box w='30%' className="table-client">
                <Text className="label-header ta-left">CLIENTS</Text>
              </Box>
              <Box w='30%' className="table-tag">
                <Text className="label-header ta-left">TAGS</Text>
              </Box>
              <Box flex='1' className="table-created">
                <Text className="label-header ta-left">CREATED</Text>
              </Box>
            </Flex>
            <Flex className="table-body">
              <Flex w="100%">
                <Flex w='30% !important' alignItems="center">
                  <Flex>
                    <Avatar name='Dan Abrahmov' src='https://bit.ly/dan-abramov' />
                    <Box className="client-infor">
                      <Text className="name">Mandison Elonhoang</Text>
                      <Text className="email">nguyenminhhoang@gmail.com</Text>
                    </Box>
                  </Flex>
                </Flex>
                <Flex w='30%' className="table-tag" alignItems="center">
                  <Flex>
                    <Tag className="tag-blue" size="lg">
                      VIP Client
                    </Tag>
                    <Tag className="tag-pink" size="lg">
                      Early Adopter
                    </Tag>
                  </Flex>
                </Flex>
                <Box flex='1' className="table-created">
                  <Text className="label-header ta-left">20/05/2022</Text>
                </Box>
              </Flex>
                
                
                
            </Flex>
          </Box>
        </Box>
    </Flex>
    <AddClientModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
}
