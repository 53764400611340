import baseService from "api/baseService";
import { AxiosResponse } from "axios";
import { UpdateUserItem } from "types/user";
import { UsersResponseData } from "./types";

export const getListUsers = (): Promise<AxiosResponse<UsersResponseData>> =>
  baseService.get(`users`);

export const me = (): Promise<AxiosResponse> => baseService.get(`auth/me`);

export const getFiles = (data: { prefix: string }): Promise<AxiosResponse> =>
  baseService.post(`upload/files`, data);

export const deleteFiles = (data: {
  paths: string[];
}): Promise<AxiosResponse> => baseService.post(`upload/files/delete`, data);

export const checkForUpdate = (): Promise<AxiosResponse> =>
  baseService.get(`auth/check-for-update`);

export const toggleTracking = (
  data: { projectId: string } | undefined
): Promise<AxiosResponse> => baseService.patch(`auth/toggle-tracking`, data);

export const adminForceOff = (data: {
  userId: string;
}): Promise<AxiosResponse> => baseService.patch(`auth/admin-force-off`, data);

export const updateUser = (data: UpdateUserItem): Promise<AxiosResponse> =>
  baseService.patch(`users`, data);

export const adminUpdateUser = (data: UpdateUserItem): Promise<AxiosResponse> =>
  baseService.patch(`users/admin/update/user`, data);
