import { TrackingTimeItem } from "types/trackingTime";

export const groupByMonth = (array: TrackingTimeItem[]) =>
  array.reduce((results: TrackingTimeItem[], item: TrackingTimeItem) => {
    const current = results.find((i: TrackingTimeItem) => {
      return (
        new Date(i.createdAt).getMonth() === new Date(item.createdAt).getMonth() &&  new Date(i.createdAt).getFullYear() === new Date(item.createdAt).getFullYear()
      );
    });
    if (current) {
      current["totalSeconds"] = current["totalSeconds"] + item["totalSeconds"];
    } else {
      results.push({ ...item });
    }
    return results;
  }, []);
