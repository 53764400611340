import { AppState } from "store";

export const getIsWorkingStatus = (state: AppState) =>
  state.trackingTime.isWorking;
export const getTrackingTimeCurrent = (state: AppState) =>
  state.trackingTime.trackingTimeCurrent;
export const getListAllTrackingTimeByOrder = (state: AppState) =>
  state.trackingTime.listAllTrackingTimeByOrder;
export const getUserSelectedByAdmin = (state: AppState) =>
  state.trackingTime.userSelectedByAdmin;
