import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Flex,
  Select,
  Switch,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { getCurrentTrackingTimeUserByAdmin } from "api/trackingTime";
import { checkForUpdate } from "api/users";
import { useProject } from "hooks/useProject";
import { userTrackingTime } from "hooks/useTrackingTime";
import { useUser } from "hooks/useUser";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setIsWorking } from "store/modules/auth/auth";
import { setUserSelectedByAdmin } from "store/modules/trackingTime/trackingTime";
import { selectors } from "store/seletors";
import { UserItem } from "types/user";

import "./styles.scss";
import TabsTracking from "./tabs";
import BaseModal from "components/Share/Modal";
import { InputAndLabel } from "components/Share/InputAndLabel";
import { useForm } from "react-hook-form";
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import axios from 'axios';

type FormValues = {
  projectId: string;
  hours: number;
  minutes: number;
};

export default function TimeTracker() {
  const currentVersion = localStorage.getItem('currentVersion');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [latestVersion, setLatestVersion] = useState<string>()
  const [disableToggleTracking, setDisableToggleTracking] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [downloadUrl, setDownloadUrl] = useState<string>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenManualLog, onOpen: onOpenManualLog, onClose: onCloseManualLog } = useDisclosure()
  const {
    isOpen: isOpenRequestManualLog,
    onOpen: onOpenRequestManualLog,
    onClose: onCloseRequestManualLog,
  } = useDisclosure();
  const {
    isOpen: isOpenRequestOT,
    onOpen: onOpenRequestOT,
    onClose: onCloseRequestOT,
  } = useDisclosure();

  const cancelRef = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch();
  const user = useSelector(selectors.auth.getUserInfo);
  const projectsLuxTech = useSelector(selectors.project.getListProjects);

  // TODO: clean code 
  // https://telegram-bot-sdk.readme.io/reference/sendmessage
  const requestLog = (message: string) => axios
    .request({
      method: 'POST',
      url: 'https://api.telegram.org/bot6077329862%3AAAG5n4z5iRlkDYOSV4atLFiIqE7h36vUhQw/sendMessage',
      headers: { accept: 'application/json', 'content-type': 'application/json' },
      data: {
        text: message,
        parse_mode: 'HTML',
        disable_web_page_preview: false,
        disable_notification: false,
        reply_to_message_id: 0,
        chat_id: '821909328'
      }
    })
    .then(function (response) {
      toast.success("Request sent");
      console.log(response.data);
    })
    .catch(function (error) {
      console.error(error);
    })

  const { userToggleTracking, getListUsersData, adminForceOffUser } =
    useUser();
  const { getListProjectsData } = useProject();
  const {
    // getTrackingTimeOfAuth,
    getAllTrackingTimeUserByOrder,
    addTrackingTimeManually
  } = userTrackingTime();
  const [workingEmployees, setWorkingEmployees] = useState<any[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const time = new Date();
  time.setSeconds(time.getSeconds() + 600); // 10 minutes timer


  const listTrackingTimeByOrder = useSelector(
    selectors.trackingTime.getListAllTrackingTimeByOrder
  );

  const userSelectedByAdmin = useSelector(
    selectors.trackingTime.getUserSelectedByAdmin
  );

  const membersLuxTech = useSelector(selectors.user.getListUser);

  const allEmployees = membersLuxTech.filter(
    (item) => item.role !== "admin"
  );

  const isWorking = useSelector(selectors.auth.getIsWorking);

  // const countMembersLuxTechActive = allEmployees.filter(
  //   (item) => item.isWorking
  // ).length;

  const handleToggleTracking = async (nextWorkingStatus: boolean) => {
    try {
      setDisableToggleTracking(true)
      if (nextWorkingStatus) {
        const allowedWebTracking = user.allowedWebTracking;
        // START WORKING
        const platform = localStorage.getItem('platform');
        if (!allowedWebTracking && !platform) return toast.error(<div>You can only stop tracking.<br /> Use desktop app to start tracking!</div>);

        if (!selectedProjectId) return toast.error("Please select a project!");

        // checkForUpdate
        const { data } = await checkForUpdate();
        const { latestVersion, downloadUrl } = data;
        setLatestVersion(latestVersion)
        setDownloadUrl(downloadUrl)
        if (!allowedWebTracking && currentVersion !== latestVersion) return onOpen()

        // check screen capture permission
        if (!allowedWebTracking && platform === 'darwin') {
          const hasScreenCapturePermission = localStorage.getItem('hasScreenCapturePermission');
          if (hasScreenCapturePermission !== 'true') return onOpen()
        }

        toast.info("Happy coding...")

        const isSuccess = await userToggleTracking({
          projectId: selectedProjectId,
        });

        if (!isSuccess) return

        dispatch(setIsWorking(nextWorkingStatus));

        const updateList = [...workingEmployees, { ...user, _id: user.id }];
        const removeDuplicationList = updateList.filter(
          (v, i, a) => a.findIndex((v2) => v2._id === v._id) === i
        );

        setWorkingEmployees(removeDuplicationList);

      } else {
        // STOP WORKING
        toast.info("Have some rest...")
        const isSuccess = await userToggleTracking();
        if (!isSuccess) return
        dispatch(setIsWorking(nextWorkingStatus));
        setWorkingEmployees((pre) => pre.filter((item) => item._id !== user.id));
      }
    } catch (error) {
      console.log(error)
    } finally {
      window.location.reload()
    }
  };


  const getInfoUser = useCallback(
    (userSelect: UserItem) => {
      dispatch(setUserSelectedByAdmin(userSelect));
      getAllTrackingTimeUserByOrder(userSelect._id);
    },
    [
      dispatch,
      getAllTrackingTimeUserByOrder,
    ]
  );

  const changeStatusOffForThisEmployee = async (item: UserItem) => {
    if (item.isWorking) {
      const res = await getCurrentTrackingTimeUserByAdmin(item._id);
      if (res.data._id) {
        const result = await adminForceOffUser({
          userId: item._id,
        });
        if (result) {
          getListUsersData();
        }
      }
    }
  };

  useEffect(() => {
    if (user.role !== "admin") {
      // getTrackingTimeOfAuth(user.id);
      user.id && getAllTrackingTimeUserByOrder(user.id);
    }
  }, [isWorking, user.id, user.role]);

  useEffect(() => {
    getListUsersData();
    getListProjectsData();
  }, []);

  useEffect(() => {
    const employeesWorking = membersLuxTech.filter(
      (item) => item.role !== "admin" && item.isWorking
    );

    setWorkingEmployees(employeesWorking);
  }, [membersLuxTech, user]);

  const { register, handleSubmit } = useForm<FormValues>({
    mode: "onBlur",
  });

  const onSubmit = async (data: FormValues) => {
    const note: string = (document?.getElementById("manual-log-note") as HTMLInputElement)?.value

    if (!data.hours || !data.minutes || !data.projectId || !note) {
      return toast.error("Please complete the form!");
    }
    const hoursToSeconds = data.hours * 60 * 60;
    const minutesToSeconds = data.minutes * 60;
    const totalSeconds = hoursToSeconds + minutesToSeconds;

    const payload = {
      projectId: data.projectId,
      totalSeconds,
      createdAt: selectedDate,
      note
    };

    setIsLoading(true)

    const res = await addTrackingTimeManually(payload);

    if (res) {
      toast.success("Success!");
      onCloseManualLog();
    } else {
      toast.error("Error when creating tracking Time!");
      onCloseManualLog();
    }
    setIsLoading(false)
  };
  const isAdmin = user.role === "admin";
  const renderStatus = (item: any) => {
    if (item.isWorking && !item.isWFH) return <Tooltip label='Working at office' fontSize='md'>
      <AvatarBadge bg={item.isWFH ? 'orange' : 'green'} boxSize='1em' />
    </Tooltip>

    if (item.isWorking && item.isWFH) return <Tooltip label='Working from home' fontSize='md'>
      <AvatarBadge bg={'orange'} boxSize='1em' />
    </Tooltip>

    if (!item.isWorking && item.isWorkLate) return <Tooltip label='Permitted to work late' fontSize='md'>
      <AvatarBadge bg={'yellow'} boxSize='1em' />
    </Tooltip>

    if (!item.isWorking && item.isOff) return <Tooltip label='OFF' fontSize='md'>
      <AvatarBadge bg={'grey'} boxSize='1em' />
    </Tooltip>

    return <Tooltip label='Not started working' fontSize='md'>
      <AvatarBadge bg={'red'} boxSize='1em' />
    </Tooltip>
  }
  return (
    <Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              LuxTrack
            </AlertDialogHeader>

            <AlertDialogBody>
              {
                currentVersion !== latestVersion ?
                  <>
                    <p>You're using an old version.</p>
                    <p>Please download and install version {latestVersion}</p>
                  </>
                  :
                  <>
                    <p>Please open System Preferences and enable Screen Recording permission to LuxTrack.</p>
                  </>
              }


            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme='blue' onClick={onClose}>
                <a target="_blank" href={downloadUrl} rel="noreferrer">{currentVersion !== latestVersion ? <span>Let's go!</span> : <span>Show instructions</span>}</a>
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <BaseModal
        isOpen={isOpenManualLog}
        onClose={onCloseManualLog}
        title='Add Tracking Time manually'
        desc={''}
        textBtn='Delete'
      >
        <Box mt='24px'>
          <Flex justifyContent="space-between">
            <Box width="45%">
              <Text fontSize="lg" fontWeight="700" mb="12px">
                Select project
              </Text>
              <Select
                {...register("projectId")}
                placeholder="Select Project"
                mb="24px"
              >
                {projectsLuxTech.filter(i => i.status === 'Open').map((item, idx) => (
                  <option value={item._id} key={idx}>
                    {idx + 1}. {item.name}
                  </option>
                ))}
              </Select>
            </Box>

            <Box width="45%">
              <Text fontSize="lg" fontWeight="700" mb="12px">
                Select date
              </Text>
              <SingleDatepicker
                name="date-input"
                date={selectedDate}
                onDateChange={setSelectedDate}
                maxDate={new Date()}
              />
            </Box>
          </Flex>

          <form onSubmit={handleSubmit(onSubmit)}>
            <InputAndLabel label='Total hours (min: 0, max: 7)' {...register('hours')} type={'number'} />
            <InputAndLabel label='Total minutes (min: 0, max: 59)' {...register('minutes')} type={'number'} />
            <Text fontSize="lg" fontWeight="700" mb="12px">
              What did you complete?
            </Text>
            <Textarea
              id='manual-log-note'
              placeholder='Describe your tasks...'
            />
            <Flex width="100%" justifyContent="right">
              <Button
                isLoading={isLoading}
                mt="30px"
                colorScheme='blue' type='submit'
              >
                Submit
              </Button>
            </Flex>
          </form>
        </Box>
      </BaseModal>

      <div>
        {/* {user.role === "admin" ? (
          <Box color="blue.500" fontWeight={"bold"}>
            Trạng thái hoạt động của nhân viên LuxTech
          </Box>
        ) : (
          <Box
            color="whatsapp.500"
            fontSize={"24px"}
            fontWeight={"bold"}
            mb="12px"
          >
            {`${countMembersLuxTechActive} máy cày đang hoạt động`}
          </Box>
        )} */}

        {!isAdmin && (
          <Flex
            justify={"center"}
            align="center"
            gap={"40px"}
            flexDir={{ base: "column", md: "row" }}
            mt="20px"
          >
            <Box>
              <Select
                placeholder="Chọn dự án đi nào!"
                onChange={(e: any) => setSelectedProjectId(e.target.value)}
                w="300px"
                border="1px solid green!important"
                m="0 auto"
                disabled={isWorking}
              // defaultValue={''}
              >
                {projectsLuxTech.filter(i => i.status === "Open").map((item, idx) => (
                  <option value={item._id} key={idx}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Box>
            <Flex
              justify="center"
              gap={2}
              align="center"
              mt={{ base: "16px", md: "0px" }}
            >
              <Text fontSize="20px">
                {isWorking
                  ? "You're currently working!"
                  : "Start working!"}
              </Text>

              <Switch
                colorScheme="teal"
                onChange={() => handleToggleTracking(!isWorking)}
                size="lg"
                isChecked={isWorking}
                disabled={disableToggleTracking}
              />
            </Flex>

          </Flex>
        )}

        {
          !isAdmin && <Flex
            justify={"center"}
            align="center"
            gap={"40px"}
            // flexDir={{ base: "column", md: "row" }}
            mt="100px"
          >

            {user.allowedManualLog ? <Button
              disabled={!user.allowedManualLog}
              fontSize={20}
              onClick={onOpenManualLog}
              colorScheme="green"
              p="24px"
            >
              Add time manually
            </Button> : <Button
              fontSize={20}
              onClick={onOpenRequestManualLog}
              colorScheme="green"
              p="24px"
            >
              Request manual log
            </Button>
            }

            {user.allowedOT ? <p>You are allowed to OT</p> : <Button
              fontSize={20}
              onClick={onOpenRequestOT}
              colorScheme="green"
              p="24px"
            >
              Request OT
            </Button>
            }
          </Flex>

        }

        <Flex py={8} justifyContent={"center"} flexWrap="wrap" gap={16}>
          {allEmployees.map((item, idx) => {
            return (
              <Box
                key={idx}
                opacity={!item.isWorking ? 0.15 : 1}
                cursor="pointer"
              >
                <Avatar
                  onClick={() => isAdmin && getInfoUser(item)}
                  size={"xl"}
                  src={item.avatarUrl}
                  css={{
                    border: "2px solid white",
                  }}
                  _hover={{ transform: "scale(1.2)" }}
                >
                  {
                    renderStatus(item)
                  }
                </Avatar>
                <Text>{item.name}</Text>
                {
                  isAdmin && <Switch
                    colorScheme="teal"
                    onChange={() => changeStatusOffForThisEmployee(item)}
                    size="lg"
                    isChecked={item.isWorking}
                  />
                }
              </Box>
            );
          })}
        </Flex>
      </div>

      {listTrackingTimeByOrder && (
        <TabsTracking
          listTrackingTimeByOrder={listTrackingTimeByOrder}
          userSelected={userSelectedByAdmin}
        />
      )}

      <BaseModal
        isOpen={isOpenRequestManualLog}
        onClose={onCloseRequestManualLog}
        title="Send a request to admin"
        desc="Are you sure you want to send a manual log request?"
        textBtn="Send"
        handleBtn={() => {
          requestLog(`<b>${user.name}</b> requests manual log`)
          onCloseRequestManualLog()
        }
        }
      />

      <BaseModal
        isOpen={isOpenRequestOT}
        onClose={onCloseRequestOT}
        title="Send a request to admin"
        desc="Are you sure you want to send an OT request?"
        textBtn="Send"
        handleBtn={() => {
          requestLog(`<b>${user.name}</b> requests OT`)
          onCloseRequestOT()
        }
        }
      />
    </Box>
  );
}
