import { createSlice } from "@reduxjs/toolkit";
import { ProjectState } from "./types";
// Slice

const initialState: ProjectState = {
  listProjects: []

}
const slice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setListProject: (state, action) => {
      state.listProjects = action.payload;
    },

    updateListProject: (state, action) => {
      state.listProjects = state.listProjects.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload
        }
        return item
      })
    },

    createProjectStore: (state, action) => {
      state.listProjects = [...state.listProjects, action.payload]
    },

  },
});
export default slice.reducer;
export const { setListProject, updateListProject, createProjectStore } = slice.actions;