import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./types";
// Slice

const initialState: AuthState = {
  user: {
    id: "",
    slackId: "",
    name: "",
    email: "",
    token: "",
    role: "",
    isAuth: false,
    isWorking: false,
    allowedOT: false,
    isOff: false,
    isWorkLate: false,
    isWFH: false,
    screenshotIntervalInMinute: 5,
    avatarUrl: "",
  },
};
const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = { ...action.payload, isAuth: true };
    },
    setUpdateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload, isAuth: true };
    },
    logoutSuccess: (state) => {
      state.user = initialState.user;
    },
    setIsWorking: (state, action) => {
      state.user.isWorking = action.payload;
    },
  },
});
export default slice.reducer;
export const { loginSuccess, logoutSuccess, setIsWorking, setUpdateUser } =
  slice.actions;
