export const COLUMNS_TABLE_REPORTS_BY_DAY_MONTHS = [
  "Month",
  "Name",
  "Report",
  "Not reported",
];

export const COLUMNS_TABLE_REPORTS_BY_DAY = [
  "Date",
  "Name",
  "Project",
  "Details",
];
