import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { UseDisclosureProps } from "types/chakraUiProps";

type FormValues = {};



export default function AddClientModal({ isOpen, onOpen, onClose }: UseDisclosureProps) {
    const [file, setFile] = useState<string | null>(null);
    const {
        handleSubmit,
    } = useForm<FormValues>();
    const [state, setState] = useState({
        avatar: '',
        email: '',
        username: '',
    });

    const onSubmit = async (data: FormValues) => {
        // if (result) {
        //   toast.success("Success!");
        //   onClose();
        // } else {
        //   toast.error("Error when created a client!");
        //   onClose();
        // }
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files && e.target.files.length > 0) {
            const file = URL.createObjectURL(e.target.files[0]);
            setFile(file);
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add a new Client</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box className='App'>
                                <FormControl>
                                    <FormLabel htmlFor='avatar'>Avatar</FormLabel>
                                    <input
                                        type='file'
                                        id='upload'
                                        accept='image/*'
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
                                    />
                                    <label htmlFor='upload'>
                                        <IconButton color='primary' aria-label='upload picture'>
                                            <Avatar
                                                id='avatar'
                                                style={{
                                                    width: '60px',
                                                    height: '60px',
                                                }}
                                            />
                                        </IconButton>
                                    </label>
                                    <label htmlFor='avatar' />
                                </FormControl>
                            </Box>
                            <FormControl isRequired>
                                <FormLabel htmlFor='email'>Email</FormLabel>
                                <Input id='email' type='email' name='email' placeholder='Email' onChange={handleInputChange} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel htmlFor='user-name'>UserName</FormLabel>
                                <Input id='user-name' placeholder='User Name' name='username' onChange={handleInputChange} />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button type='submit' colorScheme='blue'>
                                Submit
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
        </>
    );
}
