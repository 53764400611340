import { me } from "./../api/users/index";
import {
  adminForceOff,
  getListUsers,
  toggleTracking,
  updateUser as updateUserApi,
  adminUpdateUser as adminUpdateUserApi,
} from "api/users";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loginSuccess, setUpdateUser } from "store/modules/auth/auth";
import { setTrackingTimeCurrent } from "store/modules/trackingTime/trackingTime";
import { setListUser } from "store/modules/user/user";
import { UpdateUserItem } from "types/user";
import { toHHMMSS } from "utlis/formatTime";

export const useUser = () => {
  const dispatch = useDispatch();

  const getListUsersData = async () => {
    const result = await getListUsers();
    const currentUser = await me();
    const data = result?.data;

    if (currentUser) {
      localStorage.setItem("currentUser", JSON.stringify(currentUser.data));
      dispatch(setUpdateUser(currentUser.data));
    }

    if (data) {
      dispatch(setListUser(data));
    }
  };

  const userToggleTracking = async (data?: { projectId: string }) => {
    try {
      const result = await toggleTracking(data);

      if (result?.data?.msgError) {
        toast.error(result?.data?.msgError || "Please try again!");
        return false;
      }
      const userData = result?.data?.user;
      const trackingTimeCurrent = result?.data?.trackingTimeCurrent;
      window.localStorage.setItem("currentUser", JSON.stringify(userData));
      // OFF
      // Thông báo user sau khi tắt log, user đã log được bao nhiêu trong lần log này
      if (trackingTimeCurrent.totalSeconds > 0) {
        toast.success(
          `You just logged ${toHHMMSS(trackingTimeCurrent.totalSeconds)}!`
        );
      }

      if (userData) {
        dispatch(loginSuccess({ ...userData, id: userData._id }));
        dispatch(setTrackingTimeCurrent(trackingTimeCurrent));

        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const adminForceOffUser = async (data: { userId: string }) => {
    const result = await adminForceOff(data);
    return result?.data?.user;
  };

  const updateUser = async (updateUser: UpdateUserItem) => {
    await updateUserApi(updateUser);

    return true;
  };

  const adminUpdateUser = async (updateUser: UpdateUserItem) => {
    await adminUpdateUserApi(updateUser);

    return true;
  };

  return {
    getListUsersData,
    userToggleTracking,
    updateUser,
    adminForceOffUser,
    adminUpdateUser,
  };
};
