import { setBaseServiceAuthorizationHeader } from "api/baseService";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { RenderRoutes } from "routes/RenderRoutes";
import { selectors } from "store/seletors";

function App() {
  const user = useSelector(selectors.auth.getUserInfo);
  useEffect(() => {
    const tokenLocal = localStorage.getItem("lt-jwt");
    const token = user.token ?? tokenLocal;
    if (token) {
      setBaseServiceAuthorizationHeader(token);
    }
  }, [user.token]);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>LuxTrack</title>
        <meta name="description" content="From LuxTech.Global" />
      </Helmet>
      <Router>
        <RenderRoutes />
      </Router>
    </>
  );
}

export default App;
