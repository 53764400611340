import {
  Box,
  Button,
  Flex,
  HStack, useDisclosure
} from "@chakra-ui/react";
import { deleteFiles, getFiles } from "api/users";
import BaseModal from "components/Share/Modal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Gallery } from "react-grid-gallery";
import { useSearchParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { subtractTrackingTimeApi } from "api/trackingTime";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectors } from "store/seletors";

export default function Screenshots() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [images, setImages] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const selectedImages = useMemo(() => images.filter((image: any) => image.isSelected), [images])
  const hasSelected = useMemo(() => selectedImages.length > 0, [selectedImages.length])
  const user = useSelector(selectors.auth.getUserInfo);
  const SCREENSHOT_INTERVAL = Number(user.screenshotIntervalInMinute); // minutes

  const getImages = useCallback(async () => {
    try {
      const prefix = searchParams.get("prefix") || ''
      if (!!prefix) {
        const res = await getFiles({
          prefix
        })
        const images = res.data.map((img: any) => ({
          src: img.completedUrl,
          isSelected: false,
          caption: img.filename,
          pathWithFilename: img.pathWithFilename,
        }))
        setImages(images)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }, [searchParams])

  useEffect(() => {
    getImages()
  }, [getImages])

  const handleSelect = useCallback((index: number) => {
    const nextImages = images?.map((image: { isSelected: any; }, i: number) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setImages(nextImages);
  }, [images]);

  const handleSelectAllClick = useCallback(() => {
    const nextImages = images?.map((image: any) => ({
      ...image,
      isSelected: !hasSelected,
    }));
    setImages(nextImages);
  }, [hasSelected, images]);

  const renderContent = useCallback(() => {
    if (isLoading) {
      return <Flex justifyContent="center"><ScaleLoader color={'#3182ce'} /></Flex>
    }
    if (images?.length > 0) {
      return <Gallery images={images} onSelect={handleSelect} />
    }
    return <Flex justifyContent="center"><Box>No screenshots</Box></Flex>
  }, [handleSelect, images, isLoading])

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const handleDelete = useCallback((async () => {
    try {
      const prefix = searchParams.get("prefix") || ''
      if (!!prefix) {
        const arr = prefix.split("/")
        const trackingId = arr[arr.length - 1]

        const selectedImagesPaths = selectedImages.map((i: any) => i.pathWithFilename)
        await subtractTrackingTimeApi({
          trackingId,
          numImagesDeleted: selectedImages.length
        })
        // https://stackoverflow.com/questions/2013255/how-to-get-year-month-day-from-a-date-object
        await deleteFiles({
          paths: selectedImagesPaths,
        })
        onCloseDelete()
        toast.success("Successfully!")
        const unselectedImages = images?.filter((img: any) => !img.isSelected)
        setImages(unselectedImages);
      }
    } catch (error: any) {
      onCloseDelete()
      toast.error(error?.response?.data?.message || error?.message || "Something wrong!")
    }
  }), [images, onCloseDelete, searchParams, selectedImages])

  return (
    <Box padding="20px 30px" w="100%" >
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Button onClick={() => window.location.href = '/'} colorScheme="green"><ChevronLeftIcon />Back</Button>
        <HStack spacing='24px' margin='12px'>
          {
            images?.length > 0 && (
              <>
                <Button onClick={handleSelectAllClick} colorScheme="blue">
                  {hasSelected ? "Clear selection" : "Select all"}
                </Button>
                <Button
                  colorScheme='red' type='button'
                  onClick={onOpenDelete}
                  disabled={!hasSelected}
                >
                  Delete
                </Button>
              </>
            )
          }

        </HStack>
      </Flex>
      {
        renderContent()
      }
      <BaseModal
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        title='Delete?'
        desc={`Are you sure you want to delete ${selectedImages.length} images and remove ${selectedImages.length * SCREENSHOT_INTERVAL} minutes from this tracking?`}
        textBtn='OK'
        handleBtn={handleDelete}
      />
    </Box>
  );
}