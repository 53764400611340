import axios, { Axios } from "axios";
const BE_URL = process.env.REACT_APP_BE_URL;

export const API_SERVER_URL: string = `${BE_URL}/api`;

export const baseService: Axios = axios.create({
  baseURL: API_SERVER_URL,
  // baseURL: "http://localhost:4000/api",
  // baseURL: "https://be.luxtech.global/api",
});

baseService.defaults.headers.common["Accept"] = "application/json";

export default baseService;

const setAuthorizationHeader = (token: string, provider: Axios = axios) => {
  provider.defaults.headers.common["Authorization"] = token
    ? `Bearer ${token}`
    : "";
};

export const setBaseServiceAuthorizationHeader = (token: string) => {
  localStorage.setItem("lt-jwt", token);

  setAuthorizationHeader(token, baseService);
};
