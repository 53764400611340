import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import { useListReports } from "hooks/useListReports";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectors } from "store/seletors";
import { UseDisclosureProps } from "types/chakraUiProps";

type FormValues = {
  project: string;
  tasks: {
    desc: string;
  }[];
};

export default function ReportModal({ isOpen, onOpen, onClose }: UseDisclosureProps) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      tasks: [{ desc: "" }],
    },
    mode: "onBlur",
  });
  const { fields, append, remove } = useFieldArray({
    name: "tasks",
    control,
  });
  const user = useSelector(selectors.auth.getUserInfo);

  const projectsLuxTech = useSelector(selectors.project.getListProjects);

  const { sendReportByThisUser } = useListReports();

  const onSubmit = async (data: FormValues) => {
    if (!data?.project) {
      return toast.error("Please select a project!");
    }
    if (data?.tasks?.length < 1) {
      return toast.error("Please add at least one task!");
    }
    const description = data.tasks.map((item) => item.desc);
    const reportData = {
      labels: [data.project],
      description: description,
      name: user.name,
      email: user.email,
      slackId: user.slackId
    };
    const result = await sendReportByThisUser(reportData);
    if (result) {
      onClose();
    } else {
      toast.error("Error when submitting a report!");
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Report For Today</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Select
                {...register("project")}
                placeholder="Select Project"
                mb="24px"
              >
                {projectsLuxTech.filter(i => i.status === 'Open').map((item, idx) => (
                  <option value={item.name} key={idx}>
                    {idx + 1}. {item.name}
                  </option>
                ))}
              </Select>
              <Flex justify="flex-end">
                <Button
                  type="button"
                  onClick={() =>
                    append({
                      desc: "",
                    })
                  }
                  colorScheme="green"
                >
                  Add
                </Button>
              </Flex>
              {fields.map((field, index) => {
                return (
                  <div key={field.id}>
                    <Text mb="12px">Task {index + 1}:</Text>
                    <Flex className={"section"} key={field.id}>
                      <Input
                        placeholder="name"
                        {...register(`tasks.${index}.desc` as const, {
                          required: true,
                        })}
                        className={errors?.tasks?.[index]?.desc ? "error" : ""}
                        defaultValue={field.desc}
                        mb="24px"
                        mr="24px"
                      />
                      <Button
                        type="button"
                        colorScheme="red"
                        onClick={() => remove(index)}
                      >
                        DELETE
                      </Button>
                    </Flex>
                  </div>
                );
              })}
            </ModalBody>

            <ModalFooter>
              <Button type="submit" colorScheme="blue">
                Submit Report
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
