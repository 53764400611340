import { Box, Input, Text } from "@chakra-ui/react";
import { forwardRef } from "react";

type Props = {
  label: string;
  placeholder?: string;
  isReadOnly?: boolean;
  type?: string;
};
export const InputAndLabel = forwardRef<HTMLInputElement, Props>(
  function InputAndLabel(
    { label, isReadOnly, type, placeholder = "Type here...", ...rest },
    ref
  ) {
    return (
      <Box mb="24px">
        <Text fontSize="lg" fontWeight="700" mb="12px">
          {label}
        </Text>

        <Input
          isReadOnly={isReadOnly}
          ref={ref}
          placeholder={placeholder}
          bgColor={isReadOnly ? "darkseagreen" : "InfoBackground"}
          {...rest}
          type={type}
        />
      </Box>
    );
  }
);
